import React from "react";
import "../App.css";
import { FaDiscord, FaTwitter, FaTelegram } from "react-icons/fa6";

function Footer() {
  return (
    <div className="Footer">
    <a href="https://twitter.com/Akasha_Markets" target="_blank">
    < FaTwitter  
        style={{
          width: "25px",
          height: "25px",
          //color: 'white',
          color: '#26a7de',
          margin: '5px',
        }}
      />
    </a>
    <a href='https://discord.gg/zw46eS8N' target="_blank">
    < FaDiscord 
        style={{
            width: "25px",
            height: "25px",
            //color: 'white',
            color: "#7289DA",
            margin: '5px',
            }}
        />
    </a>
    </div>
  );
}

export default Footer;
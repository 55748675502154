import { getBlackScholesPrice } from './blackScholes.js'

const SECONDS_IN_YEAR = 31536000
/* global BigInt */
const UNIT = BigInt(10 ** 18)

export function getQuoteIteration(
  isBuy,
  isCall,
  size,
  spotPrice,
  skew,
  preTradeAmmNetStdVega,
  interestRate,
  strikePrice,
  baseIv,
  varianceFeeParams,
  pricingParams,
  //board contains varianceGwavIv, baseIv, expiry
  board,
  //contains NAV
  liquidityParams
) {
  const timeToExpiry = board.expiry - BigInt(Math.round(Date.now() / 1000))
  const isForceClose = false

  // Post-impact iv and skew
  let { newBaseIv: proposedNewBaseIv, newSkew } = getIVImpactForTrade(baseIv, skew, size, pricingParams.standardSize, pricingParams.skewAdjustmentFactor, isBuy)

  // Calculate (force close) base bsc price per option
  const basePriceData = getPrice(board.expiry, proposedNewBaseIv, newSkew, interestRate, spotPrice, strikePrice, isCall)
  const { price, volTraded } = basePriceData
  // Penalty
  const forceClosePenalty = 0

  // Option fee
  const optionPriceFee = getOptionPriceFee(timeToExpiry, price, size, pricingParams)
  // Spot fee
  const spotPriceFee = getSpotPriceFee(timeToExpiry, size, spotPrice, pricingParams)
  // Update AMM net standard vega
  const netStdVegaDiff = board.netGreeks.netStdVega
    * size
    * (isBuy ? BigInt(1) : BigInt(-1))
    / (UNIT)
  const postTradeAmmNetStdVega = preTradeAmmNetStdVega + netStdVegaDiff

  // Vega util fee
  const vegaUtilFee = getVegaUtilFee(liquidityParams.NAV, preTradeAmmNetStdVega, postTradeAmmNetStdVega, volTraded, size, pricingParams.vegaFeeCoefficient)
  console.log(`vega util fee: ${Number(vegaUtilFee.vegaUtilFee) / Number(UNIT)}`)
  // Skip baseIv update on force close
  const newBaseIv = proposedNewBaseIv

  /*
    spotPrice,
    strikePrice,
    volTraded,
    newSkew,
    newBaseIv,
    size,
    varianceGwavIv,
    rateAndCarry,
    expiry,
    varianceFeeParams
    */
  // Variance fee
  const varianceFee = getVarianceFee(spotPrice, strikePrice, volTraded, newSkew, newBaseIv, size, board.varianceGwavIv, interestRate, board.expiry, varianceFeeParams)

  // Total fees
  const fees = optionPriceFee + spotPriceFee + vegaUtilFee.vegaUtilFee + varianceFee.varianceFee
  console.log(`variance Util util fee: ${Number(vegaUtilFee.vegaUtilFee) / Number(UNIT)}`)
  console.log(`fees: ${Number(fees) / Number(UNIT)}`)
  // Add fees for buys, subtract fees for sells
  const base = price * size / UNIT
  console.log(`base: ${Number(base) / Number(UNIT)}`)
  console.log(`price at end: ${Number(price) / Number(UNIT)} size: ${Number(size) / Number(UNIT)}`)
  const premium = isBuy ? base + fees : fees < base ? base - fees : 0n
  console.log(`premium after fee: ${premium}`)

  return {
    premium,
    optionPriceFee,
    spotPriceFee,
    vegaUtilFee,
    varianceFee,
    forceClosePenalty,
    postTradeAmmNetStdVega,
    volTraded,
    newSkew,
    newBaseIv,
    spotPrice,
  }
}

function getIVImpactForTrade(
  baseIv,
  skew,
  size,
  standardSize,
  skewAdjustmentFactor,
  isBuy
) {

  console.log(`getIVImpactForTrade: ${baseIv} ${skew} ${size} ${standardSize} ${skewAdjustmentFactor} ${isBuy}} ${Number(size) / Number(standardSize)}`)
  const orderSize = size * (UNIT) / (standardSize) // 10^18
  console.log(`skew adjustment factor: ${Number(skewAdjustmentFactor) / Number(UNIT)}`)
  const orderMoveBaseIv = orderSize / 100n
  const orderMoveSkew = orderMoveBaseIv * skewAdjustmentFactor / UNIT
  console.log(`orderMoveBaseIv: ${orderMoveBaseIv} orderMoveSkew: ${orderMoveSkew} ${Number(standardSize) / Number(UNIT)} ${Number(orderSize) / Number(UNIT)}`)
  let newBaseIv = isBuy ? baseIv + orderMoveBaseIv : baseIv - orderMoveBaseIv
  const newSkew = isBuy ? skew + orderMoveSkew : skew - orderMoveSkew
  console.log(`newSkew: ${newSkew} newBaseIv: ${newBaseIv}`)
  return {
    newBaseIv,
    newSkew,
  }
}

// abs supports BigInt, Math.abs does not
function abs(x) {
  return x < 0n ? -x : x
}

export function getVegaUtilFee(
  NAV,
  preTradeAmmNetStdVega,
  postTradeAmmNetStdVega,
  volTraded,
  size,
  vegaFeeCoefficient
) {
  if (abs(preTradeAmmNetStdVega) > abs(postTradeAmmNetStdVega)) {
    return {
      preTradeAmmNetStdVega,
      postTradeAmmNetStdVega,
      vegaUtil: BigInt(0),
      volTraded,
      NAV,
      vegaUtilFee: BigInt(0),
    }
  }
  const vegaUtil = NAV > BigInt(0) ? volTraded * abs(postTradeAmmNetStdVega) / NAV : BigInt(0)
  const _vegaUtilFee = vegaFeeCoefficient * vegaUtil / UNIT * size / UNIT
  const vegaUtilFee = _vegaUtilFee < BigInt(0) ? BigInt(0) : _vegaUtilFee
  return {
    preTradeAmmNetStdVega,
    postTradeAmmNetStdVega,
    vegaUtil,
    volTraded,
    NAV,
    vegaUtilFee,
  }
}


export function getTimeWeightedFee(
  timeToExpiry,
  pointA,
  pointB,
  coefficient
) {
  if (timeToExpiry <= pointA) {
    return coefficient
  } else {
    const factor = (timeToExpiry - pointA) * 10 ** 18
      / (pointB - pointA)
      / 10 ** 18

    return (coefficient + factor) / 10 ** 18
  }
}

export function getOptionPriceFee(timeToExpiry, pricePerOption, size, params) {
  const timeWeightedOptionPriceFee = getTimeWeightedFee(
    timeToExpiry,
    params.optionPriceFee1xPoint,
    params.optionPriceFee2xPoint,
    params.optionPriceFeeCoefficient
  )
  return timeWeightedOptionPriceFee * size / UNIT * pricePerOption / UNIT
}


export function getSpotPriceFee(timeToExpiry, spotPrice, size, params) {
  const timeWeightedOptionPriceFee = getTimeWeightedFee(
    timeToExpiry,
    params.spotPriceFee1xPoint,
    params.spotPriceFee2xPoint,
    params.spotPriceFeeCoefficient
  )
  return timeWeightedOptionPriceFee * size / UNIT * spotPrice / UNIT
}

function getAnnualizedTimeLeft(expiry) {
  return (Number(expiry) - Date.now() / 1000) / SECONDS_IN_YEAR
}

export function getPrice(
  expiry,
  newBaseIv,
  newSkew,
  interestRate,
  spotPrice,
  strikePrice,
  isCall
) {
  console.log(`expiry: ${expiry}`)
  const timeToExpiryAnnualized = getAnnualizedTimeLeft(expiry)
  const newVol = newBaseIv * newSkew / UNIT
  console.log(`BS inputs ${spotPrice}, ${strikePrice}, ${Number(interestRate) / Number(UNIT)}, ${Number(newVol) / Number(UNIT)}, ${timeToExpiryAnnualized}, ${isCall} ${newBaseIv} ${newVol}`)
  const price =
    getBlackScholesPrice(
      Number(spotPrice),
      Number(strikePrice),
      Number(interestRate) / Number(UNIT),
      Number(newVol) / Number(UNIT),
      timeToExpiryAnnualized,
      isCall
    )
  console.log(`price: ${price.price / Number(UNIT)}`)

  return {
    price: BigInt(Math.round(price.price)),
    volTraded: newVol,
  }
}


export function getTimeToExpiryAnnualized(expiry) {
  return (Number(expiry) - Date.now() / 1000) / SECONDS_IN_YEAR
}

export default function getVarianceFee(
  spotPrice,
  strikePrice,
  volTraded,
  newSkew,
  newBaseIv,
  size,
  varianceGwavIv,
  rateAndCarry,
  expiry,
  varianceFeeParams,
) {

  const coefficient = varianceFeeParams.defaultVarianceFeeCoefficient
  const ivVariance = abs(varianceGwavIv - newBaseIv)
  const rate = rateAndCarry;
  const timeToExpiryAnnualized = getTimeToExpiryAnnualized(expiry)
  console.log(`expiry: ${expiry} ${timeToExpiryAnnualized} ${volTraded} ${spotPrice} ${strikePrice} ${rate}`)

  const vega =
    BigInt(getVega(
      timeToExpiryAnnualized,
      Number(volTraded),
      Number(spotPrice),
      Number(strikePrice),
      Number(rate)
    ) * 100)

  if (coefficient === BigInt(0)) {
    return {
      varianceFeeCoefficient: 0,
      vega,
      vegaCoefficient: 0,
      skew: newSkew,
      skewCoefficient: 0,
      ivVariance,
      ivVarianceCoefficient: 0,
      varianceFee: 0,
    }
  }
  console.log(`vegas: ${vega} ${varianceFeeParams.minimumStaticVega} ${varianceFeeParams.vegaCoefficient}`)
  const vegaCoefficient = varianceFeeParams.minimumStaticVega + vega * varianceFeeParams.vegaCoefficient / UNIT
  const skewDiff = abs(newSkew - varianceFeeParams.referenceSkew)
  const skewCoefficient = varianceFeeParams.minimumStaticSkewAdjustment +
    skewDiff * (varianceFeeParams.skewAdjustmentCoefficient) / UNIT
  const ivVarianceCoefficient = varianceFeeParams.minimumStaticIvVariance +
    ivVariance * varianceFeeParams.ivVarianceCoefficient / UNIT

  const varianceFee = coefficient
    * (vegaCoefficient)
    / (UNIT)
    * (skewCoefficient)
    / (UNIT)
    * (ivVarianceCoefficient)
    / (UNIT)
    * (size)
    / (UNIT)
  return {
    varianceFeeCoefficient: coefficient,
    vega,
    vegaCoefficient,
    skew: newSkew,
    skewCoefficient,
    ivVariance,
    ivVarianceCoefficient,
    varianceFee,
  }
}

export function stdNormal(x) {
  return Math.exp((-x * x) / 2.0) / Math.sqrt(2.0 * Math.PI)
}

export function d1(tAnnualised, vol, spot, strikePrice, rate) {
  return (Math.log(spot / strikePrice) + (rate + (vol * vol) / 2.0) * tAnnualised) / (vol * Math.sqrt(tAnnualised))
}

export function getVega(tAnnualised, vol, spot, strikePrice, rate) {
  return (spot * stdNormal(d1(tAnnualised, vol, spot, strikePrice, rate)) * Math.sqrt(tAnnualised)) / 100
}

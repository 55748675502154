import React from 'react';

function BullCallSpreadExplanation({ onClose }) {
  return (
    <div style={{display: 'flex', flexDirection: "column", height: "800px", overflowY: 'scroll', overflowX: 'hidden', textAlign: 'center'}}>
      <h2>Bull Call Spread</h2>
      <p style={{textAlign: 'left'}}>
      A bull call spread is an options strategy that involves buying a call 
      option with a lower strike price and simultaneously selling a call
      option with a higher strike price. The two call options have the 
      same expiration date and underlying asset. This strategy is used when 
      an investor has a bullish outlook on the underlying asset but wants 
      to limit their upfront cost and potential downside risk.<br></br><br></br>
      Here's how a bull call spread works:<br></br><br></br>
      1. Buy Lower Strike (In-The-Money) Call Option: The investor buys a call option with a 
      lower strike price. This option gives the investor the right to buy the 
      underlying asset at the specified strike price, known as the "long call."<br></br><br></br>
      2. Sell Higher Strike (Out-The-Money) Call Option: Simultaneously, the investor 
      sells a call option with a higher strike price. This option obligates 
      the investor to sell the underlying asset at the specified strike price, 
      known as the "short call."<br></br><br></br>
      3. Same Expiration Date: Both the long call and the short call have 
      the same expiration date, meaning they expire on the same day.<br></br><br></br>
      The bull call spread strategy is employed when an investor expects the 
      price of the underlying asset to increase moderately but wants to limit 
      their upfront cost and potential downside risk. <br></br><br></br>
      Here are some reasons to use a bull call spread:<br></br><br></br>
      1. Limited Risk, Limited Reward: The bull call spread allows the 
      investor to define their maximum risk and potential reward upfront. 
      The premium received from selling the higher strike call option 
      partially offsets the cost of buying the lower strike call option. 
      The maximum profit is capped at the difference between the strike 
      prices of the two call options, minus the net premium paid.<br></br><br></br>
      2. Cost Efficiency: Compared to buying a single call option 
      outright, the bull call spread involves a lower upfront cost. 
      By selling the higher strike call option, the investor receives a 
      premium that helps reduce the overall cost of the strategy.<br></br><br></br>
      3. Risk Mitigation: The short call option in the bull call spread helps 
      mitigate the potential downside risk. If the price of the underlying 
      asset decreases, the loss on the long call option is partially offset 
      by the premium received from selling the short call option.<br></br><br></br>
      4. Profit from Moderate Price Increase: The bull call spread strategy 
      allows the investor to profit if the price of the underlying asset rises
      moderately. The maximum profit is achieved when the price of the 
      underlying asset is above the strike price of the higher strike call 
      option at expiration.
      </p>
      <button className="smallButton" onClick={onClose}>Close</button>
    </div>
  );
}

export default BullCallSpreadExplanation;
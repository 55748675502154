import React, { useState, useEffect, useRef } from "react";
import "../../App.css";
import { useAccount } from 'wagmi'

//import from options.js; approve renamed as approve1
import {
  getCurrentBoards,
  getSpotPrice,
  getSpotPrice1,
  createOptionContract,
  getMinCollateral,
  getOptionType,
  getOptionType1,
  hasAllowance,
  approve1,
  getMinCollateralParams
} from "../../lyraConnector.js";
import { getOptionPrice } from "../../optionCalcs.js";
import { getQuoteIteration } from "../../quote.js"
import { getQuotesForBoards } from "../../retrieveData.js"

//from options.js
const UNIT = BigInt(10 ** 18)
const ETH_MARKET = "0x919E5e0C096002cb8a21397D724C4e3EbE77bC15"
const dateFormat = { day: 'numeric', month: 'short', year: 'numeric' };

function BullCallSpreadBuyPopup({ selectedAsset, onClose }) {
  
  const { address, isConnected } = useAccount();
  const [quantity, setQuantity] = useState([]);
  const [price, setPrice] = useState([]);
  const [price1, setPrice1] = useState([]);
  const [asset, setAsset] = useState(selectedAsset);
  const [asset1, setAsset1] = useState("Bitcoin");
  const [expiry, setExpiry] = useState("");
  const [expiry1, setExpiry1] = useState("");
  const [strike, setStrike] = useState("");
  const [strike1, setStrike1] = useState("");
  const [optionType, setOptionType] = useState("Call");
  const [optionType1, setOptionType1] = useState("Call");
  const [buyOrSell, setBuyOrSell] = useState("Buy");
  const [buyOrSell1, setBuyOrSell1] = useState("Buy");
  //const [openOrders, setOpenOrders] = useState([]);
  const [options, setOptions] = useState([])
  const [options1, setOptions1] = useState([])
  const [expiries, setExpiries] = useState(["All"])
  const [newOrder, setNewOrder] = useState(false)
  const [newOrder1, setNewOrder1] = useState(false)
  const [collateralRequired, setCollateralRequired] = useState(0n)
  const [collateralRequired1, setCollateralRequired1] = useState(0n)
  const [numTradesCompleted, setNumtradesCompleted] = useState(0)
  const currentExpiryFilter = useRef(new Date(0))
  const totalValueOptions = price * quantity
  const totalValueOptions1 = price1 * quantity

  useEffect(() => {
    getAllQuotes();
  }, [asset])

  const handleAssetChange = async (_asset) => {
    setAsset(_asset);
  };

  const handleExpiryChange = (expiry) => {
    console.log(`expiry: ${typeof expiry}`)
    console.log(`currentExpiryFilter: ${JSON.stringify(typeof currentExpiryFilter.current.value)}`)
    setExpiry(expiry);
  };

  const handleQuantityChange = (event) => {
    setQuantity(event.target.value);
  };

  const getAllQuotes = async () => {
    const size = 1000000n;
    const market = asset === "Bitcoin"
    //console.log(`market: ${market} ${asset}`)
    const quotes = await getQuotesForBoards(size, asset === "Ethereum" ? "ETH" : "BTC")
    //quotes.forEach(quote => {
    ////   console.log(JSON.stringify(quote, (key, value) => typeof value === 'bigint' ? value.toString() : value))
    //})
    const displayQuotes = []
    for (let i = 0; i < quotes.length; i++) {
      for (let j = 0; j < quotes[i].strikes.length; j++) {
        displayQuotes.push({
          exercisePrice: Number(quotes[i].strikes[j].strikePrice / UNIT),
          strikeId: quotes[i].strikes[j].strikeId,
          callAsk: (Number(quotes[i].strikes[j].callAsk) / Number(UNIT)).toFixed(2),
          callBid: (Number(quotes[i].strikes[j].callBid) / Number(UNIT)).toFixed(2),
          expiry: (new Date(Number(quotes[i].expiry) * 1000))
        })

      }
    }
    displayQuotes.forEach(quote => console.log(quote.expiry))
    let expirations = displayQuotes.map(quote => String(quote.expiry.getTime()))
    if (expirations.length > 0) {
      const newExpirations = [...new Set(expirations)]
      expirations.forEach(expiration => console.log(`expiration: ${Number(expiration)}`))
      newExpirations.unshift("All")
      setExpiries(newExpirations)
    }
    setOptions(displayQuotes);
  }

  const setNewOrderView = async (strikeId, isCall, isBuy, price) => {
    setNewOrder({ strikeId, isCall, isBuy })
    setBuyOrSell(isBuy ? 'Buy' : 'Sell')
    setOptionType(isCall ? 'Call' : 'Put')
    setStrike(options.filter(option => option.strikeId === strikeId)?.[0]?.exercisePrice)
    setPrice(price);

    const optionType = getOptionType(isCall, isBuy)
    const strikePrice = BigInt(options.filter(option => option.strikeId === strikeId)?.[0]?.exercisePrice) * UNIT;
    const expiry = options.filter(option => option.strikeId === strikeId)?.[0]?.expiry.getTime();
    const spotPrice = await getSpotPrice()
    const amount = BigInt(quantity * Number(UNIT));
    const minCollateral = await getMinCollateral(optionType, strikePrice, expiry - Date.now(), spotPrice, amount)
    setCollateralRequired(minCollateral)
  }

  const setNewOrderView1 = async (strikeId, isCall, isBuy, price) => {
    setNewOrder1({ strikeId, isCall, isBuy })
    setBuyOrSell1(isBuy ? 'Buy' : 'Sell')
    setOptionType(isCall ? 'Call' : 'Put')
    setStrike1(options.filter(option => option.strikeId === strikeId)?.[0]?.exercisePrice)
    setPrice1(price);

    const optionType = getOptionType(isCall, isBuy)
    const strikePrice1 = BigInt(options.filter(option => option.strikeId === strikeId)?.[0]?.exercisePrice) * UNIT;
    const expiry = options.filter(option => option.strikeId === strikeId)?.[0]?.expiry.getTime();
    const spotPrice1 = await getSpotPrice1()
    const amount = BigInt(quantity * Number(UNIT));
    const minCollateral = await getMinCollateral(optionType, strikePrice1, expiry - Date.now(), spotPrice1, amount)
    setCollateralRequired1(minCollateral)
  }

  const handleTradeExecution = async () => {
    const { strikeId, isCall, isBuy } = newOrder
    const market = asset === "Ethereum" ? "ETH" : "BTC"
    /*
    const optionType = getOptionType(isCall, isBuy)
    const strikePrice = BigInt(options.filter(option => option.strikeId === strikeId)?.[0]?.exercisePrice) * UNIT;
    const spotPrice = await getSpotPrice()
    const amount = BigInt(quantity * Number(UNIT));
    const minCollateral = await getMinCollateral(optionType, strikePrice, expiry - Date.now(), spotPrice, amount)
    */
    if ((await hasAllowance(market, address)) === false) {
      console.log("approve")
      await approve1(market)
    }

    await createOptionContract(BigInt(quantity * Number(UNIT)), strikeId, isCall, isBuy, collateralRequired, market, expiry, address)
    setNumtradesCompleted(numTradesCompleted + 1)
    /*
    console.log(`strikeId: ${strikeId}`)
    setOpenOrders(await getPositions(address))
    setIsPopupVisible(false);
    */
  };

  const handleTradeExecution1 = async () => {
    const { strikeId, isCall, isBuy } = newOrder1
    const market = asset === "Ethereum" ? "ETH" : "BTC"
    
    if ((await hasAllowance(market, address)) === false) {
      console.log("approve")
      await approve1(market)
    }

    await createOptionContract(BigInt(quantity * Number(UNIT)), strikeId, isCall, isBuy, collateralRequired1, market, expiry, address)
    setNumtradesCompleted(numTradesCompleted + 1)
    
  };

  const executeFuturesOptions = () => {
      handleTradeExecution1();
      handleTradeExecution();
    };
  
   return (
        <div style={{display: 'flex', flexDirection: "column", height: "800px", overflowY: 'scroll', overflowX: 'hidden', textAlign: 'center'}}>
          <h1 style={{textAlign:"center", marginTop:'0px', marginBottom:'0px'}}>{selectedAsset} Bull Call Spread</h1>
          <h3 style={{color:'goldenrod', marginBottom:'0px', textAlign:'left'}}>Long ITM Call</h3>
          <div>
          <label htmlFor="quantity"># of contracts:</label>
          <input
            type='number'
            id='quantity'
            value={quantity}
            onChange={handleQuantityChange}
            />
          <br></br>
          <label htmlFor="expiry" style={{ textAlign: 'left' }}>Expiry:</label>
          <select
              id="expiry"
              name="expiry"
              ref={currentExpiryFilter}
              onChange={(event) => handleExpiryChange(event.target.value)}
          >
              {expiries.map((expiry) => <option value={expiry}> {expiry === 'All' ? expiry : (new Date(Number(expiry))).toLocaleString('us-EN', dateFormat)}</option>)}
          </select>
          <br></br>
          <table>
            <thead>
              <tr>
                <th>Call Ask</th>
                <th style={{ color: 'goldenrod' }}>Strike Price</th>
                <th>Expiry</th>
              </tr>
            </thead>
            <tbody>
              {options
                .filter(option => currentExpiryFilter.current.value === "All" || option.expiry.getTime() === (new Date(Number(currentExpiryFilter.current.value))).getTime())
                .map((option) => (
                  <tr>
                    {option.callAsk && <td>{<button className="smallButton" onClick={() => setNewOrderView1(option.strikeId, true, true, option.callAsk)}> {option.callAsk} </button>}</td>}
                    <td style={{ color: 'goldenrod' }}>{option.exercisePrice}</td>
                    {option.expiry && <td>{`${option.expiry.toLocaleString('us-EN', dateFormat)}`}</td>}
                  </tr>
                ))}
            </tbody>
          </table>
          <br></br>
          Option Cost: ${(totalValueOptions1).toFixed(2)} 
          <br></br>
          Strike Price: {strike1}
          <br></br>
          Collateral Required:  
          </div>
          <br></br>
          <h3 style={{color:'goldenrod', marginTop:'-10px', marginBottom:'0px', textAlign:'left'}}>Short OTM Call</h3>
          <div>
          # of contracts: Set Above
          <br></br>
          <label htmlFor="expiry" style={{ textAlign: 'left' }}>Expiry: Set above</label>
          <table>
            <thead>
              <tr>
                <th>Put Bid</th>
                <th style={{ color: 'goldenrod' }}>Strike Price</th>
                <th>Expiry</th>
              </tr>
            </thead>
            <tbody>
              {options
                .filter(option => currentExpiryFilter.current.value === "All" || option.expiry.getTime() === (new Date(Number(currentExpiryFilter.current.value))).getTime())
                .map((option) => (
                  <tr>
                    {option.callBid && <td>{<button className="smallButton" onClick={() => setNewOrderView(option.strikeId, true, false, option.callBid)}> {option.callBid} </button>}</td>}
                    <td style={{ color: 'goldenrod' }}>{option.exercisePrice}</td>
                    {option.expiry && <td>{`${option.expiry.toLocaleString('us-EN', dateFormat)}`}</td>}
                  </tr>
                ))}
            </tbody>
          </table>
          <br></br>
          Option Premium: ${(totalValueOptions).toFixed(2)}
          <br></br>
          Strike Price: {strike}
          <br></br>
          Collateral Required: {Math.round(Number(collateralRequired) / Number(UNIT))}
          </div>
          <h3 style={{color:"goldenrod", marginTop:'-5px', marginBottom:'0px', textAlign: 'left'}}>Net Cost: ${(totalValueOptions1 + Number(collateralRequired)/Number(UNIT) - totalValueOptions).toFixed(2) }  (Cost + Collateral - Premium)</h3>
          <button className="swapButton" onClick={executeFuturesOptions} style={{marginBottom:'0px'}}>Execute Trade</button>
          <div>
          <button className="smallButton" onClick={onClose}>Close</button>
          <button className="smallButton">Help</button>
          </div>
        </div>
  );
}

export default BullCallSpreadBuyPopup;
import React from 'react';

function BullPutSpreadExplanation({ onClose }) {
  return (
    <div style={{display: 'flex', flexDirection: "column", height: "800px", overflowY: 'scroll', overflowX: 'hidden', textAlign: 'center'}}>
      <h2>Bull Put Spread</h2>
      <p style={{textAlign: 'left'}}>
      A bull put spread is an options strategy that involves selling a put 
      option with a higher strike price and simultaneously buying a put 
      option with a lower strike price. Both options have the same expiration 
      date and underlying asset. This strategy is utilized when an investor 
      has a moderately bullish outlook on the underlying asset but wants to 
      limit their upfront cost and potential downside risk.<br></br><br></br>
      Here's how a bull put spread works:<br></br><br></br>
      1. Sell Higher Strike (In-The-Money) Put Option: The investor sells a put option with 
      a higher strike price. This option obligates the investor to buy the 
      underlying asset at the specified strike price if called by the put buyer.  This is known as the "short put."<br></br><br></br>
      2. Buy Lower Strike (Out-The-Money) Put Option: Simultaneously, the investor buys a put 
      option with a lower strike price. This option gives the investor the 
      right to sell the underlying asset at the specified strike price, known 
      as the "long put."<br></br><br></br>
      3. Same Expiration Date: Both the short put and the long put have the 
      same expiration date, meaning they expire on the same day.<br></br><br></br>
      When executing a bullish put spread, the premium received from selling 
      the higher-strike put option is usually higher than the premium paid for 
      buying the lower-strike put option. This difference in premiums results 
      in a net credit or premium received by the investor.  The net premium 
      received represents the maximum potential profit for the investor. If 
      the price of the underlying asset remains above the higher strike price 
      at expiration, both options may expire worthless, allowing the investor 
      to keep the entire premium received.
      </p>
      <button className="smallButton" onClick={onClose}>Close</button>
    </div>
  );
}

export default BullPutSpreadExplanation;
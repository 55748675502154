import React from 'react';

function LongStraddleExplanation({ onClose }) {
  return (
    <div style={{display: 'flex', flexDirection: "column", height: "800px", overflowY: 'scroll', overflowX: 'hidden', textAlign: 'center'}}>
      <h2>Long Straddle</h2>
      <p style={{textAlign: 'left'}}>
      A long straddle is an options strategy that involves purchasing 
      both a call option and a put option with the same strike price and 
      expiration date. It is a strategy used when an investor expects 
      significant price volatility in the underlying asset but is unsure 
      about the direction of the price movement.<br></br><br></br>
      Here's how a long straddle works:<br></br><br></br>
      1. Purchase Call and Put Options: The investor buys a call option 
      and a put option on the same underlying asset, such as a 
      cryptocurrency. The options should have the same strike price and 
      expiration date.<br></br><br></br>
      2. Expectation of Volatility: The investor employs the long 
      straddle strategy when they anticipate a substantial price 
      movement in the underlying asset but are uncertain about the 
      direction of the movement—whether it will go up or down.<br></br><br></br>
      3. Profit Potential: If the price of the underlying asset moves 
      significantly in either direction beyond the combined cost of the 
      call and put options (including the premiums paid), the investor 
      can potentially profit. The profit is realized by exercising 
      either the call option or the put option, depending on which 
      option has gained value due to the price movement.<br></br><br></br>
      4. Limited Risk: The risk in a long straddle is limited to the 
      cost of purchasing the call and put options. If the price of the 
      underlying asset remains relatively stable, the options may expire 
      worthless, resulting in a loss limited to the premiums paid.<br></br><br></br>
      If the strikes are different for the call and put options, it is known as a "long STRANGLE" or "long 
      combination" strategy.  The strike price of the call option is 
      typically higher than the strike price of the put option, while the
      expiration dates are the same.
      </p>
      <button className="smallButton" onClick={onClose}>Close</button>
    </div>
  );
}

export default LongStraddleExplanation;
const ndist = function (z) {
  return (1.0 / (Math.sqrt(2 * Math.PI))) * Math.exp(-0.5 * z);
}


const normalcdf = function (X) {   //Approximation for cumulative standard normal distribution.
  var T = 1 / (1 + .2316419 * Math.abs(X));
  var D = .3989423 * Math.exp(-X * X / 2);
  var Prob = D * T * (.3193815 + T * (-.3565638 + T * (1.781478 + T * (-1.821256 + T * 1.330274))));
  if (X > 0) {
    Prob = 1 - Prob
  }
  return Prob
}


export const getBlackScholesPrice = function (S, K, r, v, t, isBuy) {
  var sqt = Math.sqrt(t);
  const d1 = (Math.log(S / K) + r * t) / (v * sqt) + 0.5 * (v * sqt);
  const d2 = d1 - (v * sqt);
  const delta = normalcdf(d1);
  const Nd2 = normalcdf(d2);
  const ert = Math.exp(-r * t);
  const nd1 = ndist(d1);
  const result = {}
  console.log(`t: ${t} zzz: ${sqt}, ${S}, ${K} + ${v} ${t}`)
  console.log(`d1: ${d1} d2: ${d2} delta: ${delta} Nd2: ${Nd2} ert: ${ert} nd1: ${nd1}`)
  result['price'] = isBuy ? S * delta - K * ert * Nd2 : K * ert * normalcdf(-d2) - S * normalcdf(-d1);
  return result;
  /*
  result['gamma'] = nd1 / (S * v * sqt);
  result['vega'] = S * sqt * nd1;
  result['theta'] = -(S * v * nd1) / (2 * sqt) - r * K * ert * Nd2;
  result['rho'] = K * t * ert * Nd2;
  return (result);
  */
} //end of black_scholes call

import React from 'react';

function ShortStraddleExplanation({ onClose }) {
  return (
    <div style={{display: 'flex', flexDirection: "column", height: "800px", overflowY: 'scroll', overflowX: 'hidden', textAlign: 'center'}}>
      <h2>Short Straddle</h2>
      <p style={{textAlign: 'left'}}>
      A short straddle is an options strategy where an investor sells 
      both a call option and a put option on the same underlying asset, 
      with the same strike price and expiration date. The strategy is 
      used when the investor expects the underlying asset's price to 
      remain relatively stable or range-bound.<br></br><br></br>
      Here's how a short straddle works:<br></br><br></br>
      1. Sell Call and Put Options: The investor sells a call option and a 
      put option on the same underlying asset, with identical strike prices 
      and expiration dates.<br></br><br></br>
      2. Expectation of Stability: The short straddle strategy is employed 
      when the investor anticipates that the price of the underlying asset 
      will remain stable or trade within a specific range until the options' 
      expiration. By selling both options, the investor profits from the 
      premiums received upfront.<br></br><br></br>
      3. Profit Potential: The investor profits from a short straddle when 
      the price of the underlying asset remains near the strike price. This 
      allows both the call and put options to expire worthless, and the 
      investor keeps the premiums received from selling the options.<br></br><br></br>
      4. Limited Profit, Unlimited Risk: The profit potential in a short 
      straddle is limited to the premiums received from selling the call and 
      put options. However, the risk is theoretically unlimited since there is 
      no cap on how much the underlying asset's price can move. If the price 
      significantly moves away from the strike price in either direction, the 
      investor can face losses that exceed the premiums received.<br></br><br></br>
      A short STRANGLE involves selling both a call option and a put 
      option on the same underlying asset, but with different strike 
      prices. The options have the same expiration date. The strike 
      price of the call option is typically higher than the strike price 
      of the put option.
      </p>
      <button className="smallButton" onClick={onClose}>Close</button>
    </div>
  );
}

export default ShortStraddleExplanation;
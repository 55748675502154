import React from 'react';

function BearCallSpreadExplanation({ onClose }) {
  return (
    <div style={{display: 'flex', flexDirection: "column", height: "800px", overflowY: 'scroll', overflowX: 'hidden', textAlign: 'center'}}>
      <h2>Bear Call Spread</h2>
      <p style={{textAlign: 'left'}}>
      A bear call spread involves selling a call option with a lower strike 
      price and simultaneously buying a call option with a higher strike 
      price. Both options have the same expiration date and underlying asset.<br></br><br></br> 
      This strategy is utilized when an investor has a bearish outlook on the 
      underlying asset but wants to limit their upfront cost and potential 
      downside risk.<br></br><br></br>
      Here's how a bear call spread works:<br></br><br></br>
      1. Sell Lower Strike (In-The-Money) Call Option: The investor sells a call option with 
      a lower strike price. This option obligates the investor to sell the 
      underlying asset at the specified strike price, known as the "short call", if called by the option buyer.<br></br><br></br>
      2. Buy Higher Strike (Out-The-Money) Call Option: Simultaneously, the investor buys a 
      call option with a higher strike price. This option gives the investor 
      the right to buy the underlying asset at the specified strike price, 
      known as the "long call."<br></br><br></br>
      3. Same Expiration Date: Both the short call and the long call have the 
      same expiration date, meaning they expire on the same day.<br></br><br></br>
      When executing a bearish call spread, the premium received from selling 
      the lower-strike call option is typically higher than the premium paid 
      for buying the higher-strike call option. This difference in premiums 
      results in a net credit or premium received by the investor.  The net 
      premium received represents the maximum potential profit for the investor. 
      If the price of the underlying asset remains below the higher strike price 
      at expiration, both options may expire worthless, allowing the investor to 
      keep the entire premium received.
      </p>
      <button className="smallButton" onClick={onClose}>Close</button>
    </div>
  );
}

export default BearCallSpreadExplanation;
import { React, useState, useEffect } from 'react';
import businessinfographic from '../businessinfographic.mov';
import "../App.css";
import Footer from './Footer.js';

function Home() {

  const [showDisclaimer, setShowDisclaimer] = useState(true);
  const [checkboxChecked, setCheckboxChecked] = useState(false);
  
  useEffect(() => {
    const disclaimerAccepted = sessionStorage.getItem('disclaimerAccepted');
    if (disclaimerAccepted === 'true') {
      setShowDisclaimer(false);
    }
  }, []);

  const handleCloseDisclaimer = () => {
    setShowDisclaimer(false);
    sessionStorage.setItem('disclaimerAccepted', 'true');
  };

  const handleCheckboxChange = () => {
    setCheckboxChecked(!checkboxChecked);
  };

  return (
    <section id="home" style={{display: "flex", flexDirection: "row", justifyContent: 'center'}}>
      {showDisclaimer && (
      <div className="disclaimer-popup" style={{display: "flex", flexDirection:"column", justifyContent:"center"}}>
        <h1>Disclaimer</h1>
        <p>
        This website is intended for use only by individuals residing 
        in regions where the use of Decentralized Finance (DeFi) protocols 
        are permitted.  Users are responsible for complying with the 
        applicable laws and regulations of their jurisdiction.<br></br><br></br>
        The information provided on this website is for informational 
        purposes only and should not be construed as financial advice. 
        Users should conduct their own research and seek professional 
        advice before making any financial decisions or engaging in any 
        transactions.<br></br><br></br>
        This website is currently in Alpha, which means it is 
        still under development and may contain errors or bugs. Users 
        acknowledge and accept that they use this website at their own risk. 
        The website owner and its affiliates shall not be held responsible 
        for any losses, damages, or liabilities arising from the use of this 
        website.<br></br><br></br>
        </p>
        <label>
        <input type="checkbox" checked={checkboxChecked} onChange={handleCheckboxChange} />
        <span>
        By accessing and using this website, I agree to the terms of this 
        disclaimer.
        </span>
        </label>
        <br></br>
        <br></br>
        <button onClick={handleCloseDisclaimer} className='smallButton' disabled={!checkboxChecked}>I Agree</button>
      </div>
    )}
      <video autoPlay loop muted playsInline src={businessinfographic}
      style={{width: '100%'}}></video>
      <div style={{position: "absolute", top: '100px', left: 0, zIndex: 1, width: "100%", height: "110%", backgroundColor: "rgba(0, 0, 0, 0.3)"}}></div>
      <div style={{position: "absolute", display: 'flex', flexDirection: 'column', justifyContent: "left", alignItems: 'center', flex: 1, width: "50vw", textAlign: 'left'}}>
            <h1 style={ {fontSize: '48px', marginBottom: "10px"} }>Welcome to Akasha Markets!</h1>
            <div className='flex' style={{fontSize: '28px', textAlign: 'left'}}>
            <h1 className='text-gradient font-poppins'>
            Building The Next Generation<br></br> 
            of Financial Products</h1>
            </div>
            <p className='P1' style={{paddingLeft: '50px', paddingRight: '50px'}}> Our mission is to apply advanced 
            Distributed Ledger Technologies to create a wide spectrum of financial applications and investment products.
            Currently in Stage 1 of a 3 stage roadmap, our app provides access to decentralized futures, options, and structured 
            products for investors looking to gain exposure to cryptocurrency assets. <br></br><br></br>
            Less experienced traders can take our market view and risk questionnaire to fine tune your trading strategy,
            while those with more experience may proceed directly to our trading pages.<br></br><br></br>
            Akasha Markets is built on Arbitrum, an Ethereum layer 2 solution to increase speed and lower transaction costs.
            Perpetual futures are sourced from GMX, while options are sourced from Lyra Finance.  
            Users should bridge assets over to Arbitrum before trading.
            </p>
            <br></br>
            <div>
            <Footer/>
            </div>
      </div>
    </section>
  )
}

export default Home
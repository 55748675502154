import React from 'react';

function ProtectivePutExplanation({ onClose }) {
  return (
    <div style={{display: 'flex', flexDirection: "column", height: "800px", overflowY: 'scroll', overflowX: 'hidden', textAlign: 'center'}}>
      <h2>Protective Put</h2>
      <p style={{textAlign: 'left'}}>
      A protective put strategy is an investment strategy used to protect 
      against potential downside risk or losses in an underlying asset.<br></br><br></br>
      It involves purchasing a put option on the asset to hedge against a 
      decrease in its value.<br></br><br></br>
      Here's how a protective put strategy works:<br></br><br></br>
      1. Purchase of the Underlying Asset: The investor first acquires 
      the desired amount of the underlying asset, such as a cryptocurrency 
      like Bitcoin.<br></br><br></br>
      2. Purchase of Put Option: The investor then purchases a put option 
      on the same underlying asset. A put option gives the holder the right, 
      but not the obligation, to sell the asset at a predetermined price 
      (known as the strike price) within a specified period (known as the 
      expiration date).<br></br><br></br>
      3. Protection against Downside Risk: If the price of the underlying 
      asset decreases, the value of the put option increases. The 
      investor can exercise the put option by selling the asset at the 
      higher strike price, thus limiting their potential losses.<br></br>
      </p>
      <button className="smallButton" onClick={onClose}>Close</button>
    </div>
  );
}

export default ProtectivePutExplanation;
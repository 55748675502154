import React from 'react';

function CoveredCallExplanation({ onClose }) {
  return (
    <div style={{display: 'flex', flexDirection: "column", height: "800px", overflowY: 'scroll', overflowX: 'hidden', textAlign: 'center'}}>
      <h2>Covered Call</h2>
      <p style={{textAlign: 'left'}}>
      A covered call is an options trading strategy where an investor who owns 
      an underlying asset, such as stocks or cryptocurrencies, sells a call 
      option on that asset. By doing so, the investor earns a premium from the 
      sale of the call option. The call option gives the buyer the right to 
      purchase the underlying asset at a predetermined price (strike price) 
      within a specified period.<br></br><br></br>
      A trader might use a covered call strategy as follows:
      Let's say a trader owns a certain amount of Bitcoin and expects the price 
      to remain relatively stable or slightly increase in the near term. The 
      trader can sell a call option on their Bitcoin holdings, setting a strike 
      price above the current market price. By selling the call option, they 
      receive a premium from the buyer.<br></br><br></br>
      If the price of Bitcoin remains below the strike price until the 
      expiration of the call option, the trader keeps the premium received. 
      They can continue to sell call options on their Bitcoin holdings, 
      generating additional income through premiums.<br></br><br></br>
      However, if the price of Bitcoin rises above the strike price and the 
      call option is exercised by the buyer, the trader's Bitcoin may be sold 
      at the strike price, resulting in potential missed profits if the price 
      continues to rise significantly.<br></br><br></br>
      Overall, the covered call strategy allows traders to generate income 
      from their cryptocurrency holdings while potentially limiting their 
      upside potential if the price surpasses the strike price.</p>
      <button className="smallButton" onClick={onClose}>Close</button>
    </div>
  );
}

export default CoveredCallExplanation;
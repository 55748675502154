import { readContract, prepareWriteContract, writeContract, fetchFeeData } from '@wagmi/core'
import { POSITION_ROUTER_ARBITRUM, ARBITRUM_ADDRESSES, USDC } from './utils'
//import { JsonRpcProvider, Web3Provider } from "@ethersproject/providers";

const VAULT_READER_ARBITRUM = "0xfebB9f4CAC4cD523598fE1C5771181440143F24A"
const VAULT_ADDRESS = "0x489ee077994B6658eAfA855C308275EAd8097C4A"
const POSITION_MANAGER = "0x75E42e6f01baf1D6022bEa862A28774a9f8a4A0C"
const WETH_ADDRESS = "0x82aF49447D8a07e3bd95BD0d56f35241523fBab1"
const usdgAmount = 0;
const VaultReader = (require('./abi/vaultReader.json'))
const POSITION_ROUTER_ABI = (require('./abi/positionRouter.json'))
const ROUTER_ABI = (require('./abi/router.json'))
const ORDERBOOK_ABI = (require('./abi/orderbook.json'))
const ORDERBOOK_READER_ABI = (require('./abi/orderbookReader.json'))
const VAULT_ABI = (require('./abi/vault.json'))
const ERC20_ABI = (require('./abi/erc20.json'))

const ARBITRUM_CHAIN_ID = 42161
const ROUTER_ADDRESS = "0xaBBc5F99639c9B6bCb58544ddf04EFA6802F4064"

/* global BigInt */

// parseEther("0.000300001"),
//const DECREASE_ORDER_EXECUTION_GAS_FEE = BigInt(300001) * BigInt(10 ** 9)
const DECREASE_ORDER_EXECUTION_GAS_FEE = BigInt(215000) * BigInt(10 ** 9)


export async function getVaultInfo(tokenAddress) {
  const result = await readContract(
    {
      address: VAULT_READER_ARBITRUM,
      abi: VaultReader,
      functionName: 'getVaultTokenInfoV4',
      args: [
        VAULT_ADDRESS,
        POSITION_MANAGER,
        WETH_ADDRESS,
        11,
        ["0x82aF49447D8a07e3bd95BD0d56f35241523fBab1"]
      ],
      chainId: ARBITRUM_CHAIN_ID
    }
  )
  return result;
}


export async function createIncreasePositionEth(tokenAddress, inputAmount, usdAmount, acceptablePrice, isLong) {
  const MIN_AMOUNT_OUT = 0
  const sizeDelta = BigInt(usdAmount * 10 ** 30);
  const referralCode = '0x0000000000000000000000000000000000000000000000000000000000000000'
  const callbackTarget = '0x0000000000000000000000000000000000000000'
  const executionFee = await getFee();
  acceptablePrice = BigInt(acceptablePrice * 10 ** 30);
  const path = tokenAddress === WETH_ADDRESS ? [tokenAddress] : [WETH_ADDRESS, tokenAddress]
  const config = await prepareWriteContract({
    address: POSITION_ROUTER_ARBITRUM,
    abi: POSITION_ROUTER_ABI,
    functionName: 'createIncreasePositionETH',
    args: [
      path,
      tokenAddress,
      MIN_AMOUNT_OUT,
      sizeDelta,
      + isLong, // convert to number
      acceptablePrice,
      executionFee,
      referralCode,
      callbackTarget
    ],
    chainId: ARBITRUM_CHAIN_ID,
    value: BigInt(inputAmount * 10 ** 18)

  })
  writeContract(config);
}




export async function getMaxPrice(tokenAddress) {
  const vaultInfo = await getVaultInfo(tokenAddress);
  return vaultInfo[11]
}


export async function isPluginApproved(account) {
  const result = await readContract(
    {
      address: ROUTER_ADDRESS,
      abi: ROUTER_ABI,
      functionName: 'approvedPlugins',
      args: [
        account,
        POSITION_ROUTER_ARBITRUM,
      ],
      chainId: ARBITRUM_CHAIN_ID
    }
  )
  return result
}



export async function allowance(token, account, spender) {
  const result = await readContract(
    {
      address: token,
      abi: ERC20_ABI,
      functionName: 'allowance',
      args: [
        account,
        spender,
      ],
      chainId: ARBITRUM_CHAIN_ID
    }
  )
  return result
}


export async function approve(token, spender, amount) {
  const config = await prepareWriteContract({
    address: token,
    abi: ERC20_ABI,
    functionName: 'approve',
    args: [
      spender,
      amount,
    ],
    chainId: ARBITRUM_CHAIN_ID,
  })
  console.log(`approve: ${amount.toString()}`);
  await writeContract(config)
}



export async function routerPluginApproval() {
  const config = await prepareWriteContract({
    address: ROUTER_ADDRESS,
    abi: ROUTER_ABI,
    functionName: 'approvePlugin',
    args: [
      POSITION_ROUTER_ARBITRUM,
    ],
    chainId: ARBITRUM_CHAIN_ID,
  })
  console.log(`approvePlugin: ${JSON.stringify(config)}`);
  writeContract(config)
}


async function getFeeFromPositionRouter() {
  return await readContract(
    {
      address: ARBITRUM_ADDRESSES.OrderBook,
      abi: POSITION_ROUTER_ABI,
      functionName: 'minExecutionFee',
      chainId: ARBITRUM_CHAIN_ID
    }
  )
}

export async function getOrders(type, address) {
  return await readContract(
    {
      address: ARBITRUM_ADDRESSES.OrderBook,
      abi: ORDERBOOK_ABI,
      functionName: type,
      chainId: ARBITRUM_CHAIN_ID,
      args: [address]
    }
  )
}

export async function getOrdersFromReader(type, address) {
  return await readContract(
    {
      address: ARBITRUM_ADDRESSES.OrderBookReader,
      abi: ORDERBOOK_READER_ABI,
      functionName: type,
      chainId: ARBITRUM_CHAIN_ID,
      args: [address]
    }
  )
}



async function getFee() {
  const multiplier = 2150000n;
  const gasPrice = (await fetchFeeData({
    chainId: ARBITRUM_CHAIN_ID,
    formatUnits: "gwei",
  })).gasPrice;

  const estimatedFee = gasPrice * multiplier;
  const fee = await getFeeFromPositionRouter()
  return estimatedFee > fee ? estimatedFee : fee;
}

export async function getPosition(account, collateralToken, indexToken, isLong) {
  return await readContract(
    {
      address: ARBITRUM_ADDRESSES.Vault,
      abi: VAULT_ABI,
      functionName: 'getPosition',
      chainId: ARBITRUM_CHAIN_ID,
      args: [account, collateralToken, indexToken, isLong]
    }
  )
}





//Function: createDecreasePosition(address[] _path,address _indexToken,uint256 _collateralDelta,uint256 _sizeDelta,bool _isLong,address _receiver,uint256 _acceptablePrice,uint256 _minOut,uint256 _executionFee,bool _withdrawETH,address _callbackTarget)
export async function createDecreasePosition(account, tokenAddress, inputAmount, acceptablePrice, isLong) {
  const MIN_AMOUNT_OUT = 0
  const callbackTarget = '0x0000000000000000000000000000000000000000'
  const executionFee = await getFee();
  acceptablePrice = BigInt(acceptablePrice * 10 ** 30);
  const path = isLong ? [tokenAddress] : [USDC]//=== WETH_ADDRESS ? [tokenAddress] : [WETH_ADDRESS, tokenAddress]
  const doWithdrawETH = tokenAddress === WETH_ADDRESS && isLong ? 1 : 0;

  const config = await prepareWriteContract({
    address: POSITION_ROUTER_ARBITRUM,
    abi: POSITION_ROUTER_ABI,
    functionName: 'createDecreasePosition',
    args: [
      path,
      tokenAddress,
      0,
      inputAmount,
      + isLong,
      account,
      acceptablePrice,
      MIN_AMOUNT_OUT,
      executionFee,
      doWithdrawETH,
      callbackTarget
    ],
    chainId: ARBITRUM_CHAIN_ID,
    value: executionFee,

  })
  writeContract(config);
}



export async function createIncreasePosition(tokenAddress, inputAmount, usdAmount, acceptablePrice, isLong) {
  const MIN_AMOUNT_OUT = 0
  const sizeDelta = BigInt(usdAmount * 10 ** 30);
  const referralCode = '0x0000000000000000000000000000000000000000000000000000000000000000'
  const callbackTarget = '0x0000000000000000000000000000000000000000'
  const executionFee = await getFee();
  const path = isLong ? [tokenAddress] : [tokenAddress, USDC];
  acceptablePrice = BigInt(acceptablePrice * 10 ** 30);
  const config = await prepareWriteContract({
    address: POSITION_ROUTER_ARBITRUM,
    abi: POSITION_ROUTER_ABI,
    functionName: 'createIncreasePosition',
    args: [
      path,
      tokenAddress,
      inputAmount,
      MIN_AMOUNT_OUT,
      sizeDelta,
      + isLong, // convert to number
      acceptablePrice,
      executionFee,
      referralCode,
      callbackTarget
    ],
    chainId: ARBITRUM_CHAIN_ID,
    value: executionFee
  })

  writeContract(config);
}


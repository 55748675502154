import {
  getLiveBoards,
  getSpotPrice,
  getInterestRate,
  getLiquidity,
  getVarianceFeeParams,
  getPricingParams
} from "./lyraConnector.js"
import { getQuoteIteration } from "./quote.js"
/* global BigInt */
//const UNIT = 10n ** 18n
const UNIT = BigInt(10 ** 18)


async function getOptionPrice(strikePrice, isCall, isBuy, size, volatility, skew, board, spotPrice, interestRate, liquidityParams, varianceFeeParams, pricingParams) {
  let preTradeAmmNetStdVega = board.netGreeks.netStdVega * -1n
  let baseIv = volatility
  const numIterations = 1;
  const iterations = []
  for (let i = 0; i < numIterations; i++) {
    const quote = getQuoteIteration(
      isBuy,
      isCall,
      size,
      spotPrice,
      skew,
      preTradeAmmNetStdVega,
      interestRate,
      strikePrice,
      baseIv,
      varianceFeeParams,
      pricingParams,
      board,
      liquidityParams
    )

    baseIv = quote.newBaseIv
    skew = quote.newSkew
    preTradeAmmNetStdVega = quote.postTradeAmmNetStdVega
    iterations.push(quote)
  }

  const fairIv = board.baseIv * skew / UNIT
  const rate = interestRate
  // console.log(`premiumzz: ${iterations.at(-1).premium} ${typeof iterations.at(-1).premium}`)
  if (typeof iterations.at(-1).premium !== 'bigint') {
    throw new Error(`premium is bigint ${iterations.at(-1).premium}}`)
  }
  const premium = iterations.reduce((sum, quote) => sum + quote.premium, BigInt(0))
  const pricePerOption = premium * UNIT / size
  return pricePerOption

}

export async function getQuotesForExpiry(board, size, spotPrice, interestRate, liquidityParams, varianceFeeParams, pricingParams) {

  const usedStrikes = board.strikes.filter(strike => Math.abs(Number(strike.strikePrice) / Number(spotPrice) - 1) < .05);
  board['atmStrikes'] = usedStrikes;
  let quotes = [];

  for (let i = 0; i < usedStrikes.length; i++) {
    quotes.push({});
    const types = ['callBid', 'callAsk', 'putBid', 'putAsk'];
    for (let j = 0; j < types.length; j++) {
      let baseIv = BigInt(Number(board.baseIv)); //create deep copy
      let skew = BigInt(Number(usedStrikes[i].skew));
      quotes[i][types[j]] = await getOptionPrice(
        usedStrikes[i].strikePrice,
        types[j].includes("call"),
        types[j].includes("Bid") === false,
        size,
        baseIv,
        skew,
        board,
        spotPrice,
        interestRate,
        liquidityParams,
        varianceFeeParams,
        pricingParams);
    }
    await Promise.all([quotes[i]['callBid'], quotes[i]['callAsk'], quotes[i]['putBid'], quotes[i]['putAsk']])
    quotes[i]['strikePrice'] = usedStrikes[i].strikePrice;
    quotes[i]['strikeId'] = usedStrikes[i].strikeId;
  }
  return quotes;
}


export async function getQuotesForBoards(size, market) {
  const boards = await getLiveBoards(market);
  // console.log(`boards: ${JSON.stringify(boards, (key, value) => typeof value === 'bigint' ? value.toString() : value)}`)
  const spotPrice = await getSpotPrice(market)
  // console.log(`spotPrice: ${spotPrice}`)
  const interestRate = await getInterestRate();
  const liquidityParams = await getLiquidity(market);
  const varianceFeeParams = await getVarianceFeeParams();
  const pricingParams = await getPricingParams();
  const marketQuotes = []
  for (let i = 0; i < boards.length; i++) {
    const quotes = await getQuotesForExpiry(boards[i], size, spotPrice, interestRate, liquidityParams, varianceFeeParams, pricingParams);


    marketQuotes.push({
      expiry: boards[i].expiry,
      strikes: quotes
    })

    //console.log(
    //  JSON.stringify(marketQuotes, (key, value) => typeof value === 'bigint' ? value.toString() : value)
    //)


  }
  return marketQuotes;
}
import React, { useState, useEffect, useRef } from "react";
import "../App.css";
import { useAccount } from 'wagmi'
import {
  getCurrentBoards,
  getSpotPrice,
  getPricingParams,
  getLiveBoards,
  getLiquidity,
  getInterestRate,
  getVarianceFeeParams,
  fetchLiveBoards,
  createOptionContract,
  getOwnerPositions,
  closePosition,
  getMinCollateral,
  getOptionType,
  hasAllowance,
  approve1
} from "../lyraConnector.js";
import { getOptionPrice } from "../optionCalcs.js";
import { getQuoteIteration } from "../quote.js"
import { getQuotesForBoards } from "../retrieveData.js"

const UNIT = BigInt(10 ** 18)
const ETH_MARKET = "0x919E5e0C096002cb8a21397D724C4e3EbE77bC15"
const dateFormat = { day: 'numeric', month: 'short', year: 'numeric' };

function Options() {
  const [asset, setAsset] = useState("Ethereum");
  const [expiry, setExpiry] = useState("2023-06-30");
  const [strike, setStrike] = useState("");
  const [isPopupVisible, setIsPopupVisible] = useState(false);
  const [optionType, setOptionType] = useState("Call");
  const [buyOrSell, setBuyOrSell] = useState("Buy");
  const [price, setPrice] = useState("");
  const [quantity, setQuantity] = useState("");
  const [activeTable, setActiveTable] = useState('openPositions');
  const [openOrders, setOpenOrders] = useState([]);
  const [options, setOptions] = useState([])
  const [expiries, setExpiries] = useState(["All"])
  const [newOrder, setNewOrder] = useState(false)
  const [collateralRequired, setCollateralRequired] = useState(0n)
  const [openPositions, setOpenPositions] = useState([])
  const [numTradesCompleted, setNumtradesCompleted] = useState(0)
  const currentExpiryFilter = useRef(new Date(0))
  const totalValue = price * quantity
  const { address, isConnected } = useAccount()
  const [closeRatio, setCloseRatio] = useState(1);

  useEffect(() => {
    const getUserOrders = async () => {
      if (isConnected) {
        setOpenPositions(await getPositions(address))
      } else {
        setOpenPositions([])
      }
    }
    getUserOrders();
  }, [address, isConnected, numTradesCompleted])

  useEffect(() => {
    getAllQuotes()
    const interval = setInterval(() => getAllQuotes(), 60000)
    return () => clearInterval(interval)
  }, [])

  useEffect(() => {
    getAllQuotes();
  }, [asset])

  const handleAssetChange = async (_asset) => {
    setAsset(_asset);
  };

  const handleExpiryChange = (expiry) => {
    console.log(`expiry: ${typeof expiry}`)
    console.log(`currentExpiryFilter: ${JSON.stringify(typeof currentExpiryFilter.current.value)}`)
    setExpiry(expiry);
  };

  const handlePopupClose = () => {
    setIsPopupVisible(false);
  };

  const handleOptionTypeChange = (optionType) => {
    setOptionType(optionType);
  };

  const handleStrikeChange = (strike) => {
    setStrike(strike);
  };

  const handleBuyOrSellChange = (buyOrSell) => {
    setBuyOrSell(buyOrSell);
  };

  const handlePriceChange = (price) => {
    setPrice(price);
  };

  const handleQuantityChange = (quantity) => {
    setQuantity(quantity);
  };

  const getAllQuotes = async () => {
    const size = 1000000n;
    const market = asset === "Ethereum" ? "ETH" : "BTC"
    console.log(`market: ${market} ${asset}`)
    const quotes = await getQuotesForBoards(size, asset === "Ethereum" ? "ETH" : "BTC")
    //quotes.forEach(quote => {
    ////   console.log(JSON.stringify(quote, (key, value) => typeof value === 'bigint' ? value.toString() : value))
    //})
    const displayQuotes = []
    for (let i = 0; i < quotes.length; i++) {
      for (let j = 0; j < quotes[i].strikes.length; j++) {
        displayQuotes.push({
          exercisePrice: Number(quotes[i].strikes[j].strikePrice / UNIT),
          strikeId: quotes[i].strikes[j].strikeId,
          callBid: (Number(quotes[i].strikes[j].callBid) / Number(UNIT)).toFixed(2),
          callAsk: (Number(quotes[i].strikes[j].callAsk) / Number(UNIT)).toFixed(2),
          putBid: (Number(quotes[i].strikes[j].putBid) / Number(UNIT)).toFixed(2),
          putAsk: (Number(quotes[i].strikes[j].putAsk) / Number(UNIT)).toFixed(2),
          expiry: (new Date(Number(quotes[i].expiry) * 1000))
        })
      }
    }
    displayQuotes.forEach(quote => console.log(quote.expiry))
    let expirations = displayQuotes.map(quote => String(quote.expiry.getTime()))
    if (expirations.length > 0) {
      const newExpirations = [...new Set(expirations)]
      expirations.forEach(expiration => console.log(`expiration: ${Number(expiration)}`))
      newExpirations.unshift("All")
      setExpiries(newExpirations)
    }
    setOptions(displayQuotes);
  }

  const getPositions = async (address) => {
    const markets = await getOwnerPositions(address)
    console.log(`positions: ${JSON.stringify(markets, (key, value) => typeof value === 'bigint' ? value.toString() : value)}`)
    const displayPositions = []
    markets.forEach(market => {
      market.positions.forEach(position => {
        displayPositions.push({
          buyOrSell: position.optionType === 0 || position.optionType === 1 ? 'Buy' : 'Sell',
          asset: market.market === ETH_MARKET ? 'ETH' : 'BTC',
          optionType: position.optionType === 0 || position.optionType === 3 ? 'Call' : 'Put',
          strike: options.filter(option => option.strikeId === position.strikeId)?.[0]?.exercisePrice,
          quantity: Number(position.amount) / Number(UNIT),
          price: position.price,
          totalValue: position.size,
          expiry: options.filter(option => option.strikeId === position.strikeId)?.[0]?.expiry?.toLocaleString(),
          optionTypeNum: position.optionType,
          positionId: position.positionId,
          strikeId: position.strikeId,
        })
      })
    })
    return displayPositions;
  }

  const setNewOrderView = async (strikeId, isCall, isBuy, price) => {
    setNewOrder({ strikeId, isCall, isBuy })
    setBuyOrSell(isBuy ? 'Buy' : 'Sell')
    setOptionType(isCall ? 'Call' : 'Put')
    setStrike(options.filter(option => option.strikeId === strikeId)?.[0]?.exercisePrice)
    setPrice(price);

    const optionType = getOptionType(isCall, isBuy)
    const strikePrice = BigInt(options.filter(option => option.strikeId === strikeId)?.[0]?.exercisePrice) * UNIT;
    const expiry = options.filter(option => option.strikeId === strikeId)?.[0]?.expiry.getTime();
    const spotPrice = await getSpotPrice()
    const amount = BigInt(quantity * Number(UNIT));
    const minCollateral = await getMinCollateral(optionType, strikePrice, expiry - Date.now(), spotPrice, amount)
    setCollateralRequired(minCollateral)
  }

  const handleTradeExecution = async () => {
    const { strikeId, isCall, isBuy } = newOrder
    const market = asset === "Ethereum" ? "ETH" : "BTC"
    /*
    const optionType = getOptionType(isCall, isBuy)
    const strikePrice = BigInt(options.filter(option => option.strikeId === strikeId)?.[0]?.exercisePrice) * UNIT;
    const spotPrice = await getSpotPrice()
    const amount = BigInt(quantity * Number(UNIT));
    const minCollateral = await getMinCollateral(optionType, strikePrice, expiry - Date.now(), spotPrice, amount)
    */
    if ((await hasAllowance(market, address)) === false) {
      console.log("approve")
      await approve1(market)
      await new Promise(r => setTimeout(r, 4000));
    }

    await createOptionContract(BigInt(quantity * Number(UNIT)), strikeId, isCall, isBuy, collateralRequired, market, expiry, address)
    setNumtradesCompleted(numTradesCompleted + 1)
    /*
    console.log(`strikeId: ${strikeId}`)
    setOpenOrders(await getPositions(address))
    setIsPopupVisible(false);
    */
  };

  const resetInputs = async () => {
    //console.log(`getmin collateral ${JSON.stringify(await getMinCollateralParams(), (key, value) => typeof value === 'bigint' ? value.toString() : value)}`)
    setOptionType('Call');
    setBuyOrSell('Buy');
    setPrice('');
    setQuantity('');
    setStrike('');
  }

  //const openOrders = [
  // define open orders here...
  //];

  const handleClosePosition = async (strikeId, positionId, size, optionType) => {
    const ratio = BigInt(closeRatio * 100);
    size = (BigInt(size) * ratio) / 100n;   
    console.log(`strikeId: ${strikeId} positionId: ${positionId} size: ${size} optionType: ${optionType}`);
    await closePosition(strikeId, positionId, size, optionType, asset === "Ethereum" ? "ETH" : "BTC");
    setOpenPositions(await getPositions(address));
  };

  /*
  const openPositions = [
    // define open positions here...
  ];
  */
  const historicalTrades = [
    // define historical trades here...
  ];

  const handleTableChange = (event) => {
    setActiveTable(event.target.value);
  };

  const renderTable = () => {
    if (activeTable === 'openOrders') {
      return (
        <table>
        </table>
      );
    } else if (activeTable === 'openPositions') {
      return (
        <table>
        </table>
      );
    } else if (activeTable === 'historicalTrades') {
      return (
        <table>
        </table>
      );
    }
  };

  const help = () => {
    setIsPopupVisible(true);
  };

  const refresh = () => {
    getPositions();
  };

  return (
    <div className='optionsPage'>
      <div className="tradeBoxOptions" style={{ height: "500px", overflowY: 'scroll', overflowX: 'hidden', textAlign: 'center'}}>
        <div>
          <h1>Options</h1>
          <div style={{ textAlign: 'left' }}>
            <label htmlFor="assetId" style={{ textAlign: 'left' }}>Asset:</label>
            <select
              id="assetId"
              name="assetId"
              value={asset}
              onChange={event => handleAssetChange(event.target.value)}
            >
              <option value="Bitcoin">Bitcoin</option>
              <option value="Ethereum">Ethereum</option>
            </select>
          </div>
          <div style={{ textAlign: 'left' }}>
            <label htmlFor="expiry" style={{ textAlign: 'left' }}>Expiry:</label>
            <select
              id="expiry"
              name="expiry"
              ref={currentExpiryFilter}
              onChange={(event) => handleExpiryChange(event.target.value)}
            >
              {expiries.map((expiry) => <option value={expiry}> {expiry === 'All' ? expiry : (new Date(Number(expiry))).toLocaleString('us-EN', dateFormat)}</option>)}
            </select>
          </div>
          <br></br>
          <table>
            <thead>
              <tr>
                <th>Call Bid</th>
                <th>Call Ask</th>
                <th style={{ color: 'goldenrod' }}>Strike Price</th>
                <th>Put Bid</th>
                <th>Put Ask</th>
                <th>Expiry</th>
              </tr>
            </thead>
            <tbody>
              {options
                .filter(option => currentExpiryFilter.current.value === "All" || option.expiry.getTime() === (new Date(Number(currentExpiryFilter.current.value))).getTime())
                .map((option) => (
                  <tr>
                    {option.callBid && <td>{<button className="smallButton" onClick={() => setNewOrderView(option.strikeId, true, false, option.callBid)}> {option.callBid} </button>}</td>}
                    {option.callAsk && <td>{<button className="smallButton" onClick={() => setNewOrderView(option.strikeId, true, true, option.callAsk)}> {option.callAsk} </button>}</td>}
                    <td style={{ color: 'goldenrod' }}>{option.exercisePrice}</td>
                    {option.putBid && <td>{<button className="smallButton" onClick={() => setNewOrderView(option.strikeId, false, false, option.putBid)}> {option.putBid}</button>}</td>}
                    {option.putAsk && <td>{<button className="smallButton" onClick={() => setNewOrderView(option.strikeId, false, true, option.putAsk)}> {option.putAsk}</button>} </td>}
                    {option.expiry && <td>{`${option.expiry.toLocaleString('us-EN', dateFormat)}`}</td>}
                  </tr>
                ))}
            </tbody>
          </table>
        </div>
      </div>
      <div className='tradeBox2' style={{ textAlign: 'center'}}>
        <h1>Trade</h1>
        <div className='tradeDetails'>
          <div>
            <label htmlFor="buy-or-sell">{`Buy/Sell: ${buyOrSell}`}</label>
          </div>
          <div>
            <label >{`Option Type: ${optionType}`}</label>
          </div>
          <div>
            <label htmlFor="strike">{`Strike Price: ${strike}`}</label>
          </div>
          <div>
            <label>{`Option Price: ${price}`}</label>
          </div>
          <div>
            <label htmlFor="quantity"># of Contracts:</label>
            <input
              type="number"
              id="quantity"
              name="quantity"
              value={quantity}
              onChange={(event) => handleQuantityChange(event.target.value)}
            />
          </div>
          <div>
            <p>Total Option Premium: {buyOrSell === "Buy" ? totalValue.toFixed(2) : ""}
            <br></br>
            Collateral Required: {buyOrSell === "Buy" ? "" : "$"+ Math.round(Number(collateralRequired) / Number(UNIT))}</p>
          </div>
        </div>
        <div style={{ textAlign: 'left' }}>{buyOrSell} {quantity} {asset} {optionType} Options with {strike} strike @ ${price}. <button className="swapButton" onClick={() =>
          handleTradeExecution()}>Execute Trade</button></div>
        <div>
          <button type="button" className='smallButton' onClick={resetInputs}>Reset Trade Details</button>
          <button type="button" onClick={ help } className='smallButton'>Instructions</button>
        </div>
        <br></br>
        {isPopupVisible && (
          <div className='popup' style={{ height: '600px'}}>
            <h2>Instructions</h2>
            <p style={{ textAlign: 'left' }}>
            These options are sourced from Lyra Finance, which is a decentralized options market maker.
            Please note that Bitcoin options on Lyra, Arbitrum chain, are currently not available.
            <br></br>  
            <br></br>
            1) Please Connect Wallet to Arbitrum.<br></br>
            2) In Options Box, choose Bitcoin or Ethereum.<br></br>
            3) Choose Expiry date.<br></br>
            4) To Buy, click on either the Call Ask or Put Ask buttons.<br></br>
            5) To Short, click on either the Call Bid or Put Bid buttons.<br></br>
            6) To unwind an existing position, go to the Positions box at the bottom and choose your Close Ratio.  The Close Ratio
            sets how much of your position you wish to close out, with 1 equal to 100%.
            Click the Close button to close out your trade.<br></br>
            7) You may double check your positions at Lyra Finance.<br></br>
            <br></br>
            All trades require USDC.e (note this is NOT USDC).  Please check Arbiscan for the token contract address to import USDC.e into your wallet.  For shorting options, the minimum
            collateral required is $400 in USDC.e and could be higher depending on the size of the trade. <br></br>
            <br></br>
            Akasha Markets is currently in Alpha and is FREE TO USE however, charges from Lyra Finance and Metamask (both in ETH) do apply.<br></br>
            </p>
            <br></br>
            <button type="button" onClick={handlePopupClose} className='smallButton'>Close</button>
          </div>
        )}
      </div>
      <div className="orders" style={{ textAlign: 'center'}}>
        <h1 style={{ marginBottom: '0' }}>Positions</h1>
        <div className="dropdown" style={{ textAlign: 'left' }}>
          Displaying: &nbsp;
          <select value={activeTable} onChange={handleTableChange}>
            <option value='openOrders'>Outstanding Orders</option>
            <option value='openPositions'>Current Positions</option>
            <option value='historicalTrades'>Historical Trades</option>
          </select>
          <div style={{ display:'flex', alignItems:'center', justifyContent:'space-between'}}>
            <div>
              <label>Close Ratio:  </label>
              <input type="number" value={closeRatio} onChange={e => setCloseRatio(e.target.value)} />
            </div>
            <button onClick={refresh} className="smallButton">Refresh Positions</button>
          </div>
        </div>
        <br></br>
        {renderTable()}

        <div className='table'>
          <table>
            <thead>
              <tr>
                <th style={{ width: '8%' }}>Buy/Sell</th>
                <th style={{ width: '8%' }}>Asset</th>
                <th style={{ width: '8%' }}>Option</th>
                <th style={{ width: '8%' }}>Strike</th>
                <th style={{ width: '8%' }}>Quantity</th>
                <th style={{ width: '8%' }}>Price</th>
                <th style={{ width: '9%' }}>Value</th>
                <th style={{ width: '8%' }}>Collateral</th>
                <th style={{ width: '20%' }}>Expiry</th>
                <th style={{ width: '17%' }}>Close</th>
              </tr>
            </thead>
            <tbody>
              {activeTable === 'openOrders' && openOrders.map((order, index) => (
                <tr key={index}>
                  <td>{order.buyOrSell}</td>
                  <td>{order.asset}</td>
                  <td>{order.optionType}</td>
                  <td>{order.strike}</td>
                  <td>{order.quantity}</td>
                  <td>{order.price}</td>
                  <td>{order.totalValue}</td>
                  <td>{order.expiry}</td>
                  <td> <button onClick={() => handleClosePosition(order.strikeId, order.positionId, BigInt((order.quantity) * Number(UNIT)), order.optionTypeNum)}> Close </button></td>
                </tr>
              ))}
              {activeTable === 'openPositions' && openPositions.map((position, index) => (
                <tr key={index}>
                  <td>{position.buyOrSell}</td>
                  <td>{position.asset}</td>
                  <td>{position.optionType}</td>
                  <td>{position.strike}</td>
                  <td>{position.quantity}</td>
                  <td>{position.price}</td>
                  <td>{position.totalValue}</td>
                  <td>{position.collateral}</td>
                  <td>{position.expiry}</td>
                  <td> <button className="smallButton" onClick={() => handleClosePosition(position.strikeId, position.positionId, BigInt((position.quantity) * Number(UNIT)), position.optionTypeNum)}>Close Position</button></td>
                </tr>
              ))}
              {activeTable === 'historicalTrades' && historicalTrades.map((trade, index) => (
                <tr key={index}>
                  <td>{trade.buyOrSell}</td>
                  <td>{trade.asset}</td>
                  <td>{trade.optionType}</td>
                  <td>{trade.strike}</td>
                  <td>{trade.quantity}</td>
                  <td>{trade.price}</td>
                  <td>{trade.totalValue}</td>
                  <td>{trade.expiry}</td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>
    </div >
  );
}

export default Options;
import React from "react";
import { Line } from "react-chartjs-2";
import 'chart.js/auto';

const LongStraddlePayoffDiagram = ({payoffData}) => {
  const optionData = {
    labels: [10, 20, 30, 40, 50, 60, 70, 80, 90, 100],
    datasets: [
        {
        label: "Long Straddle",
        data: [15, 5, -5, -15, -25, -15, -5, 5, 15, 25],
        fill: false,
        borderColor: "red",
        lineTension: 0.1,
        },
        {
        label: "Long Put",
        data: [30, 20, 10, 0, -10, -10, -10, -10, -10, -10],
        fill: false,
        borderColor: "yellow",
        lineTension: 0.1,
      },
      {
        label: "Long Call",
        data: [-10, -10, -10, -10, -10, 0, 10, 20, 30, 40],
        fill: false,
        borderColor: "green",
        lineTension: 0.1,
      },
      {
        label: "Cost",
        data: [-5, -5, -5, -5, -5, -5, -5, -5, -5, -5],
        fill: false,
        borderColor: "blue",
        lineTension: 0.1,
        }, 
    ]
  };

  const optionChartOptions = {
    responsive: true,
    maintainAspectRatio: true,
    scales: {
      x: {
        title: {
          display: true,
          text: 'Price at Expiry',
          color: 'black'
        }   
      },
      
      y: {
        title: {
          display: true,
          text: 'Profit/Loss',
          color: 'black'
        }   
      }
    }
  }

  return (
    <div className="optionPayoffDiagram" style={{ width: '800px', height: '450px', textAlign: 'center'}}>
      <h3>Long Straddle Payoff Diagram</h3>
      <Line data={optionData} options={optionChartOptions} />
    </div>
  );
};

export default LongStraddlePayoffDiagram;
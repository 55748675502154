import { useState } from "react";
import { Link } from 'react-router-dom';
import { Profile } from "./connector";
import Logo from "../Akasha Main Logo 800x600.png"
import "../App.css";

function Header () {

    return (
        <header>
        <div className='leftH'>
        <img src={Logo} alt="logo" className='logo' style={{ width: '250px', height: '200px', margin: '10px 0 0 0'}} />
        <div>
          <Link to="/Home" className='link'>
            <div className='headerItem'>Home</div>
          </Link>
          <Link to="/Questionnaire" className='link'>
            <div className='headerItem'>Questionnaire</div>
          </Link>
          <Link to="/futures" className='link'>
            <div className='headerItem'>Futures</div>
          </Link>
          <Link to="/options" className='link'>
            <div className='headerItem'>Options</div>
          </Link>
          <Link to="/Available" className='link'>
            <div className='headerItem'>Strategies</div>
          </Link>
        </div>
      </div>
      <div className='rightH'>      
        <Profile />
      </div>
        </header>
    )

}

export default Header;

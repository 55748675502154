import { readContract, prepareWriteContract, writeContract, fetchFeeData } from '@wagmi/core'
import { call_price } from './blackScholes.js'
const OPTION_MARKET_ADDRESS = "0x919E5e0C096002cb8a21397D724C4e3EbE77bC15"
const OPTION_MARKET_ABI = (require('./abi/optionMarket.json'))
const OPTION_LISTING_MANAGER_ABI = (require('./abi/optionListingManager.json'))
const OPTION_LISTING_MANAGER_ADDRESS = "0x5ddC94611b64fCd7A3fB41F4FacFF06F21eA751F"
const ZERO_ADDRESS = '0x0000000000000000000000000000000000000000'
const ARBITRUM_CHAIN_ID = 42161
const OPTION_MARKET_PRICER_ABI = require('./abi/optionMarketPricer.json')
const OPTION_MARKET_PRICER_ADDRESS = "0xdacEE745b517C9cDfd7F749dFF9eB03f51a27A13"
const OPTION_MARKET_VIEWER_ADDRESS = "0x0527A05c3CEBc9Ef8171FeD29DE5900A7ea093a4"
const OPTION_MARKET_VIEWER_ABI = require('./abi/optionMarketViewer.json')
const OPTION_LIQUIDITY_POOL_ABI = require('./abi/optionLiquidityPool.json')
const OPTION_LIQUIDITY_POOL_ADDRESS = "0xB619913921356904Bf62abA7271E694FD95AA10D"
const GMX_ADAPTOR_ABI = (require('./abi/gmxAdaptor.json'))
const GMX_ADAPTOR_ADDRESS = "0x7D135662818d3540bd6f23294bFDB6946c52C9AB"
const OPTION_GREEK_CACHE_ADDRESS = "0x4b236Ac3B8d4666CbdC4E725C4366382AA30d86b"
const OPTION_GREEK_CACHE_ABI = require('./abi/optionGreekCache.json')

const OPTION_MARKET_BTC_ADDRESS = "0xe044919cf58dFb066FC9DE7c69C7db19f336B20c"
const OPTION_LISTING_MANAGER_BTC_ADDRESS = "0x057ADDcf86Cb62C13B0e5fe7a1312730a4259A29"
const OPTION_LIQUIDITY_POOL_BTC_ADDRESS = "0xEC6F3ef9481e7B8484290edBaE2CEDcdb0Ce790e"
const USDC_ADDRESS = "0xFF970A61A04b1cA14834A43f5dE4533eBDDB5CC8"
const ERC20_ABI = (require('./abi/erc20.json'))
/* global BigInt */

export async function createOptionContract(size, strikeId, isCall, isBuy, minCollateral, market) {
  //console.log(`isbuy: ${isBuy}, isCall: ${isCall}`)
  /*
  struct TradeInputParameters {
    // id of strike
    uint strikeId;
    // OptionToken ERC721 id for position (set to 0 for new positions)
    uint positionId;
    // number of sub-orders to break order into (reduces slippage)
    uint iterations;
    // type of option to trade
    OptionType optionType;
    // number of contracts to trade
    uint amount;
    // final amount of collateral to leave in OptionToken position
    uint setCollateralTo;
    // revert trade if totalCost is below this value
    uint minTotalCost;
    // revert trade if totalCost is above this value
    uint maxTotalCost;
    // referrer emitted in Trade event, no on-chain interaction
    address referrer;
  }
0xd6c0bb4400000000000000000000000000000000000000000000000000000000000001750000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000300000000000000000000000000000000000000000000000000000000000000020000000000000000000000000000000000000000000000003782dace9d900000000000000000000000000000000000000000000000000000150ee0552c2898a7000000000000000000000000000000000000000000000004e53348c8387c96d0ffffffffffffffffffffffffffffffffffffffffffffffffffffffffffffffff0000000000000000000000000000000000000000000000000000000000000000

uint strikeId;
uint positionId;
uint iterations;
OptionType optionType;
uint amount;
uint setCollateralTo;
uint minTotalCost;
uint maxTotalCost;
address referrer;

 enum OptionType {
    LONG_CALL,
    LONG_PUT,
    SHORT_CALL_BASE,
    SHORT_CALL_QUOTE,
    SHORT_PUT_QUOTE
  }

  */

  /*
  [0]:  0000000000000000000000000000000000000000000000000000000000000124
  [1]:  0000000000000000000000000000000000000000000000000000000000000000
  [2]:  0000000000000000000000000000000000000000000000000000000000000003
  [3]:  0000000000000000000000000000000000000000000000000000000000000000
  [4]:  0000000000000000000000000000000000000000000000000de0b6b3a7640000
  [5]:  0000000000000000000000000000000000000000000000000000000000000000
  [6]:  0000000000000000000000000000000000000000000000000000000000000000
  [7]:  00000000000000000000000000000000000000000000000078174e1e1bc629f1
  [8]:  0000000000000000000000000000000000000000000000000000000000000000
  */


  const optionType = (isCall ? 0 : 1) + (isBuy ? 0 : 3)
  //console.log(`before openPosition: ${optionType}`)
  //console.log(`strikeId: ${strikeId}`)
  //console.log(`size: ${size}`)
  //console.log(`minCollateral: ${minCollateral}`)
  //console.log(`market: ${market}`)

  const config = await prepareWriteContract({
    address: market === "ETH" ? OPTION_MARKET_ADDRESS : OPTION_MARKET_BTC_ADDRESS,
    abi: OPTION_MARKET_ABI,
    functionName: 'openPosition',
    args: [[
      strikeId,
      0,
      3,
      optionType,
      size,
      minCollateral,
      0,
      10 ** 40,
      ZERO_ADDRESS
    ]
    ],
    chainId: ARBITRUM_CHAIN_ID,
    value: 0
  })
  //console.log("try to send order")
  writeContract(config);
}

export async function closePosition(strikeId, positionId, size, optionType, market) {
  /*
uint strikeId;
uint positionId;
uint iterations;
OptionType optionType;
uint amount;
uint setCollateralTo; 
uint minTotalCost;
uint maxTotalCost;
address referrer;

  MethodID: 0x3c8e7624
[0]:  0000000000000000000000000000000000000000000000000000000000000175
[1]:  0000000000000000000000000000000000000000000000000000000000003041
[2]:  0000000000000000000000000000000000000000000000000000000000000003
[3]:  0000000000000000000000000000000000000000000000000000000000000000
[4]:  00000000000000000000000000000000000000000000000030927f74c9de0000
[5]:  0000000000000000000000000000000000000000000000000000000000000000
[6]:  000000000000000000000000000000000000000000000002c277024cfd55489b
[7]:  ffffffffffffffffffffffffffffffffffffffffffffffffffffffffffffffff
[8]:  0000000000000000000000000000000000000000000000000000000000000000
*/
  //console.log("closePosition called")

  const config = await prepareWriteContract({
    address: market === "ETH" ? OPTION_MARKET_ADDRESS : OPTION_MARKET_BTC_ADDRESS,
    abi: OPTION_MARKET_ABI,
    functionName: 'closePosition',
    args: [[
      strikeId,
      positionId,
      3,
      optionType,
      size,
      0,
      0,
      10 ** 40,
      ZERO_ADDRESS
    ]
    ],
    chainId: ARBITRUM_CHAIN_ID,
    value: 0
  })
  writeContract(config);
}


export async function getAllBoardDetails() {
  return await readContract({
    address: OPTION_LISTING_MANAGER_ADDRESS,
    abi: OPTION_LISTING_MANAGER_ABI,
    functionName: 'getAllBoardDetails',
    args: [
    ],
    chainId: ARBITRUM_CHAIN_ID
  })
}


export function parseGetAllBoardDetails(boards) {
  boards.forEach((board, index) => {
    boards[index].strikes.sort((a, b) => Number(a.strikePrice - b.strikePrice))
  })
  boards.sort((a, b) => Number(a.expiry - b.expiry))
  return boards
}

//A board is a collection of strikes for a given expiry
export async function getCurrentBoards() {
  const zz = parseGetAllBoardDetails(await getAllBoardDetails())
  const tt = zz.filter(board => board.expiry > Math.floor(Date.now() / 1000));
  //console.log(`boards length: ${JSON.stringify(tt.length)} `)
  /*
  console.log(`allBoard: ${JSON.stringify(zz, (key, value) =>
    typeof value === 'bigint'
      ? value.toString()
      : value // return everything else unchanged
  )
    } `)
    */
}

export async function getSpotPrice(market) {
  const address = market === 'ETH' ? OPTION_LISTING_MANAGER_ADDRESS : OPTION_LISTING_MANAGER_BTC_ADDRESS
  return await readContract({
    address: address,
    abi: OPTION_LISTING_MANAGER_ABI,
    functionName: 'getSpotPrice',
    args: [
    ],
    chainId: ARBITRUM_CHAIN_ID
  })
}

export async function getSpotPrice1(market) {
  const address = market === 'ETH' ? OPTION_LISTING_MANAGER_ADDRESS : OPTION_LISTING_MANAGER_BTC_ADDRESS
  return await readContract({
    address: address,
    abi: OPTION_LISTING_MANAGER_ABI,
    functionName: 'getSpotPrice',
    args: [
    ],
    chainId: ARBITRUM_CHAIN_ID
  })
}

/*
struct PricingParameters {
  // Percentage of option price that is charged as a fee
  uint optionPriceFeeCoefficient;
  // Refer to: getTimeWeightedFee()
  uint optionPriceFee1xPoint;
  uint optionPriceFee2xPoint;
  // Percentage of spot price that is charged as a fee per option
  uint spotPriceFeeCoefficient;
  // Refer to: getTimeWeightedFee()
  uint spotPriceFee1xPoint;
  uint spotPriceFee2xPoint;
  // Refer to: getVegaUtilFee()
  uint vegaFeeCoefficient;
  // The amount of options traded to move baseIv for the board up or down 1 point (depending on trade direction)
  uint standardSize;
  // The relative move of skew for a given strike based on standard sizes traded
  uint skewAdjustmentFactor;
}
*/
export async function getPricingParams() {
  let params = await readContract({
    address: OPTION_MARKET_PRICER_ADDRESS,
    abi: OPTION_MARKET_PRICER_ABI,
    functionName: 'pricingParams',
    args: [],
    chainId: ARBITRUM_CHAIN_ID
  })
  /*
  console.log(`params: ${JSON.stringify(params, (key, value) =>
    typeof value === 'bigint'
      ? value.toString()
      : value // return everything else unchanged
  )
    } `)
    */

  return {
    optionPriceFeeCoefficient: params[0],
    optionPriceFee1xPoint: params[1],
    optionPriceFee2xPoint: params[2],
    spotPriceFeeCoefficient: params[3],
    spotPriceFee1xPoint: params[4],
    spotPriceFee2xPoint: params[5],
    vegaFeeCoefficient: params[6],
    standardSize: params[7],
    skewAdjustmentFactor: params[8]
  }
}

async function fetchLiveBoards(market) {
  const ETH_MARKET_ADDRESS = "0x919E5e0C096002cb8a21397D724C4e3EbE77bC15"
  const address = market === 'ETH' ? ETH_MARKET_ADDRESS : OPTION_MARKET_BTC_ADDRESS

  return await readContract({
    address: OPTION_MARKET_VIEWER_ADDRESS,
    abi: OPTION_MARKET_VIEWER_ABI,
    functionName: 'getLiveBoards',
    args: [address],
    chainId: ARBITRUM_CHAIN_ID
  })
}

export async function getLiveBoards(market) {
  const boards = await fetchLiveBoards(market)
  boards.forEach((board) => {
    board.strikes.sort((a, b) => Number(a.strikePrice - b.strikePrice))
  })
  boards.sort((a, b) => Number(a.expiry - b.expiry))
  //for (const x in boards) {
  //  console.log(`\n\n board: ${x} ${JSON.stringify(boards[x], (key, value) => typeof value === 'bigint' ? value.toString() : value)}`)
  //}

  return boards

}

async function readFromChain(abi, address, functionName, args) {
  return await readContract({
    abi,
    address,
    functionName,
    args,
    chainId: ARBITRUM_CHAIN_ID
  })
}


export async function getLiquidity(market) {

  return await readContract({
    abi: OPTION_LIQUIDITY_POOL_ABI,
    address: market === "ETH" ? OPTION_LIQUIDITY_POOL_ADDRESS : OPTION_LIQUIDITY_POOL_BTC_ADDRESS,
    functionName: 'getLiquidity',
    args: [],
    chainId: ARBITRUM_CHAIN_ID
  })
}


export async function getInterestRate() {
  const ETH_MARKET_ADDRESS = "0x919E5e0C096002cb8a21397D724C4e3EbE77bC15"
  return await readContract({
    abi: GMX_ADAPTOR_ABI,
    address: GMX_ADAPTOR_ADDRESS,
    functionName: 'rateAndCarry',
    args: [ETH_MARKET_ADDRESS],
    chainId: ARBITRUM_CHAIN_ID
  })
}




export async function getVarianceFeeParams() {
  let params = await readContract({
    address: OPTION_MARKET_PRICER_ADDRESS,
    abi: OPTION_MARKET_PRICER_ABI,
    functionName: 'varianceFeeParams',
    args: [],
    chainId: ARBITRUM_CHAIN_ID
  })
  console.log(`params: ${JSON.stringify(params, (key, value) =>
    typeof value === 'bigint'
      ? value.toString()
      : value // return everything else unchanged
  )
    } `)

  return {
    defaultVarianceFeeCoefficient: params[0],
    forceCloseVarianceFeeCoefficient: params[1],
    skewAdjustmentCoefficient: params[2],
    referenceSkew: params[3],
    minimumStaticSkewAdjustment: params[4],
    vegaCoefficient: params[5],
    minimumStaticVega: params[6],
    ivVarianceCoefficient: params[7],
    minimumStaticIvVariance: params[8]
  }
}

/*
  struct MarketOptionPositions {
    address market;
    OptionToken.OptionPosition[] positions;
  }
  struct OptionPosition {
    uint positionId;
    uint strikeId;
    OptionMarket.OptionType optionType;
    uint amount;
    uint collateral;
    PositionState state;
  }
*/

export async function getOwnerPositions(address) {
  return await readContract({
    address: OPTION_MARKET_VIEWER_ADDRESS,
    abi: OPTION_MARKET_VIEWER_ABI,
    functionName: 'getOwnerPositions',
    args: [address],
    chainId: ARBITRUM_CHAIN_ID
  })
}


export async function getMinCollateralParams() {
  /*
    struct MinCollateralParameters {
    // Minimum collateral that must be posted for a short to be opened (denominated in quote)
    uint minStaticQuoteCollateral;
    // Minimum collateral that must be posted for a short to be opened (denominated in base)
    uint minStaticBaseCollateral;
    */
  /* Shock Vol:
   * Vol used to compute the minimum collateral requirements for short positions.
   * This value is derived from the following chart, created by using the 4 values listed below.
   *
   *     vol
   *      |
   * volA |____
   *      |    \
   * volB |     \___
   *      |___________ time to expiry
   *         A   B
   */
  /*
  uint shockVolA;
  uint shockVolPointA;
  uint shockVolB;
  uint shockVolPointB;
  // Static percentage shock to the current spot price for calls
  uint callSpotPriceShock;
  // Static percentage shock to the current spot price for puts
  uint putSpotPriceShock;
  */
  const zzz = readContract({
    address: OPTION_GREEK_CACHE_ADDRESS,
    abi: OPTION_GREEK_CACHE_ABI,
    functionName: 'getMinCollatParams',
    args: [],
    chainId: ARBITRUM_CHAIN_ID
  })
  console.log(`zzz${zzz}`)
  return zzz

}

export function getOptionType(isCall, isBuy) {
  return (isCall ? 0 : 1) + (isBuy ? 0 : 3)
}


export async function getMinCollateral(optionType, strikePrice, expiry, spotPrice, amount) {
  /*
    struct MinCollateralParameters {
    // Minimum collateral that must be posted for a short to be opened (denominated in quote)
    uint minStaticQuoteCollateral;
    // Minimum collateral that must be posted for a short to be opened (denominated in base)
    uint minStaticBaseCollateral;
    */
  /* Shock Vol:
   * Vol used to compute the minimum collateral requirements for short positions.
   * This value is derived from the following chart, created by using the 4 values listed below.
   *
   *     vol
   *      |
   * volA |____
   *      |    \
   * volB |     \___
   *      |___________ time to expiry
   *         A   B
   */
  /*
  uint shockVolA;
  uint shockVolPointA;
  uint shockVolB;
  uint shockVolPointB;
  // Static percentage shock to the current spot price for calls
  uint callSpotPriceShock;
  // Static percentage shock to the current spot price for puts
  uint putSpotPriceShock;
  */
  const zzz = await readContract({
    address: OPTION_GREEK_CACHE_ADDRESS,
    abi: OPTION_GREEK_CACHE_ABI,
    functionName: 'getMinCollateral',
    args: [optionType, strikePrice, expiry, spotPrice, amount],
    chainId: ARBITRUM_CHAIN_ID
  })
  //console.log(`zzz${zzz}`)
  return zzz

}





async function allowance(token, account, spender) {
  const result = await readContract(
    {
      address: token,
      abi: ERC20_ABI,
      functionName: 'allowance',
      args: [
        account,
        spender,
      ],
      chainId: ARBITRUM_CHAIN_ID
    }
  )
  //console.log("here")
  return result
}

export async function hasAllowance(market, account) {
  let allowanceAmt = await allowance(
    USDC_ADDRESS,
    account,
    market === 'ETH' ? OPTION_MARKET_ADDRESS : OPTION_MARKET_BTC_ADDRESS
  )
  return allowanceAmt > 10 ** 40;
}


export async function approve1(market) {
  const config = await prepareWriteContract({
    address: USDC_ADDRESS,
    abi: ERC20_ABI,
    functionName: 'approve',
    args: [
      market === 'ETH' ? OPTION_MARKET_ADDRESS : OPTION_MARKET_BTC_ADDRESS,
      BigInt(10 ** 50),
    ],
    chainId: ARBITRUM_CHAIN_ID,
  })
  await writeContract(config)
}


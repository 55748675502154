import React, { useState } from 'react';
import Modal from './modal.js'
import "../App.css";

const ANSWER_OPTIONS = [
  [
    { text: 'A) I am very bearish.', value: 'VeryBearish' },
    { text: 'B) I am bearish.', value: 'Bearish' },
    { text: 'C) I am neutral.', value: 'Neutral' },
    { text: 'D) I am bullish.', value: 'Bullish' },
    { text: 'E) I am very bullish.', value: 'VeryBullish' }
  ],
  [
    { text: 'A) Market sentiment is very negative as it seems like everyone is talking about the end of the world.', value: 'VeryHighIV' },
    { text: 'B) The market has been more volatile than usual and it is likely to settle down going forward.', value: 'HighIV' },
    { text: 'C) I do not see anything coming up in the near future that will disrupt the market in either direction.', value: 'MedIV' },
    { text: 'D) The market is quiet, but upcoming events have the potential to move the market in an uncertain direction.', value: 'LowIV' },
    { text: 'E) There are some very significant, upcoming events that most investors are not paying attention to.', value: 'VeryLowIV' }
  ],
  [
    { text: 'A) I have never heard of calls or puts before.', value: 'New' },
    { text: 'B) I am aware of derivatives and would be willing to learn about these products to hedge my market exposure.', value: 'Aware' },
    { text: 'C) I have some experience trading derivatives and understand the risks associated with leverage.', value: 'SomeExp' },
    { text: 'D) I understand how to use futures and options to build sophisticated financial instruments.', value: 'Understand' },
    { text: 'E) I trade the Greeks.', value: 'Greeks' }
  ],
  [
    { text: 'A) I trade daily or intraday.', value: 'Daily' },
    { text: 'B) I usually hold a position for a few weeks at a time.', value: 'Weekly' },
    { text: 'C) My normal investment horizon spans months.', value: 'Monthly' },
    { text: 'D) I am a long term investor and prefer to Discount Cost Average into my investments.', value: 'LongTerm' },
    { text: 'E) I do not have a preferred investment horizon and am comfortable trading short and long term positions.', value: 'Any' }
  ],
  [
    { text: 'A) I do not like taking risks and prefer products that offer a safe yield.', value: 'RiskAdverse' },
    { text: 'B) I am willing to take some risk in an investment, but prefer to hedge out broader market risk if possible.', value: 'SomeRisk' },
    { text: 'C) I understand that the risk of capital loss directly correlates with potential investment returns.', value: 'AcceptRisk' },
    { text: 'D) I fully appreciate the risks of leverage and will use it for very specific investments.', value: 'Leverage' },
    { text: 'E) I am an experienced or professional investor.', value: 'Pro' }
  ],
  // Repeat for Questions 3-5
];

function QuestionnaireForm() {
  const [answers, setAnswers] = useState(['', '', '', '', '']);
  const [showModal, setShowModal] = useState(false);
  const [recommendation, setRecomendation] = useState('');
  const handleAnswerSelection = (questionIndex, answerIndex) => {
    const answerValue = ANSWER_OPTIONS[questionIndex][answerIndex].value;
    const updatedAnswers = [...answers];
    updatedAnswers[questionIndex] = answerValue;
    setAnswers(updatedAnswers);
  };

  // Define a function to handle when the user submits their answers
  async function handleSubmit(event) {
    event.preventDefault(); //prevent default form submission behaviour
    console.log('Submitting answers:', answers);

    const response = await fetch ('http://localhost:3001/api/recommend', {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json'
        },
        body: JSON.stringify(answers)
    });
    const data = await response.json();
    setRecomendation(data.recommendation)
    setShowModal(true);
    console.log('Q3: Response from backend:', recommendation);
  };
  
  const handleCloseModal = () => {
    setShowModal(false);
  };

  const handleReset = () => {
    setAnswers(['', '', '', '', '']);
    window.location.reload();
  }

return (
  <div style={{display: "flex", justifyContent:'center'}}>
  <div className="tradeBoxQuestionnaire" style={{height: "700px", overflowY: 'scroll', overflowX: 'hidden'}}>
    <form onSubmit={handleSubmit}>
      <div style={{ textAlign: 'left', padding: '20px' }}>
      <h1 style={{ textAlign: 'center' }}>Market View and Risk Preferences</h1>
      <br></br>
          <p className='P1'>
          Financial markets are extremely complex and trading is a lot more nuanced than simple Buys 
          or Sells. Inexperienced investors often don't fully understand the risks of derivatives 
          or leverage, and even market professionals can struggle to build a position that encapsulates 
          their market view. The questionnaire below was designed to help you formulate a 
          trading strategy based on your market view and trading preferences. It is not necessary to fill out this 
          form before using the rest of the app.
          </p>
          <br></br>
        <div>  
          <h2>1. In the foreseeable future, which of the following statements best represents your outlook for the market:</h2>
          {ANSWER_OPTIONS[0].map((option, index) => (
            <label key={index}>
              <input
              type="radio"
              name="question1"
              value={index}
              checked={answers[0] === option.value}
              onChange={() => handleAnswerSelection(0, index)}
              />
              {option.text}
              <br />
            </label>
        ))}
        </div>
        <div>
          <h2>2. Which of the following are you seeing in the market at the moment:</h2>
          {ANSWER_OPTIONS[1].map((option, index) => (
            <label key={index}>
              <input
              type="radio"
              name="question2"
              value={index}
              checked={answers[1] === option.value}
              onChange={() => handleAnswerSelection(1, index)}
              />
              {option.text}
              <br />
            </label>
          ))}
        </div>
        <div>
          <h2>3. Which of the following statements best describes your knowledge of derivatives:</h2>
          {ANSWER_OPTIONS[2].map((option, index) => (
            <label key={index}>
              <input
              type="radio"
              name="question3"
              value={index}
              checked={answers[2] === option.value}
              onChange={() => handleAnswerSelection(2, index)}
              />
              {option.text}
              <br />
            </label>
          ))}
        </div>
        <div>
          <h2>4. What is your preferred investment horizon?</h2>
          {ANSWER_OPTIONS[3].map((option, index) => (
            <label key={index}>
              <input
              type="radio"
              name="question4"
              value={index}
              checked={answers[3] === option.value}
              onChange={() => handleAnswerSelection(3, index)}
              />
              {option.text}
              <br />
            </label>
          ))}
        </div>
        <div>
          <h2>5. Which of the following statements best describes you as an investor?</h2>
          {ANSWER_OPTIONS[4].map((option, index) => (
            <label key={index}>
              <input
              type="radio"
              name="question5"
              value={index}
              checked={answers[4] === option.value}
              onChange={() => handleAnswerSelection(4, index)}
              />
              {option.text}
              <br />
            </label>
          ))}
        </div>
        <br />
        <button type="submit" className="button">Submit</button>
        <button style={{ float: 'right'}} type="button" onClick={handleReset} className="button">
          Reset Form
        </button>
      </div >
    </form>
    <div className='modal-container' style={
      {display: showModal ? 'flex' : 'none' }}>
        <Modal onClose={handleCloseModal}
        recommendation={recommendation} />
    </div>
    </div>
    </div>
  );       
}

export default QuestionnaireForm;

import logo from './logo.svg';
import './App.css';
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import { useEffect, useState } from 'react';
import Home from "./components/Home.js";
//import Swap from "./components/Swap";
import Futures from './components/futures.js';
import Options from './components/options.js';
import Header from './components/Header.js';
import Available from "./components/Available";
import QuestionnaireForm from "./components/Questionnaire.js";
import { WagmiConfig, createConfig, useAccount, useConnect, mainnet } from 'wagmi';
import { createPublicClient, http } from 'viem';
import { arbitrum } from '@wagmi/core/chains';
import axios from 'axios';

const config = createConfig({
  autoConnect: true,
  publicClient: createPublicClient({
    chain: arbitrum,
    transport: http()
  }),
})

function App() {

  return (
    <WagmiConfig config={config}>
     <BrowserRouter>  
    <Header />
      <Routes> 
        <Route path='/' element={<Home />} />
        <Route path='/Home' element={<Home />} />
        <Route path='/Questionnaire' element={<QuestionnaireForm />} />
        <Route path='/futures' element={<Futures />} />
        <Route path='/options' element={<Options />} />
        <Route path='/Available' element={<Available />} />
      </Routes>
    </BrowserRouter>   
    </WagmiConfig>

  );
}

export default App;
import React, { useState, useEffect, useRef } from "react";
import "../App.css";
//import { Profile } from "./connector/index.js";
import { useAccount } from 'wagmi'
import {
  createIncreasePositionEth,
  createIncreasePosition,
  routerPluginApproval,
  isPluginApproved,
  getPosition,
  createDecreasePosition,
  approve,
  allowance,
} from "../gmxChainInfo"
import { fetchGMXPrices, WBTC_ARB, WETH_ARB, DECIMALS, POSITION_ROUTER_ARBITRUM, ROUTER, USDC } from "../utils";
import TradingViewWidget from "./TradingView";
import bigInt from "big-integer";
/* global BigInt */

const WETH_ADDRESS_ARBITRUM = "0x82aF49447D8a07e3bd95BD0d56f35241523fBab1"
const TOKENS = { 'WETH': WETH_ARB, 'WBTC': WBTC_ARB }
const DECIMALS_GMX = 30

function Futures() {

  const [selectedAsset, setSelectedAsset] = useState('BTC');
  const [priceData, setPriceData] = useState([]);
  const [orderType, setOrderType] = useState('Buy');
  const [quantity, setQuantity] = useState([]);
  const [price, setPrice] = useState([]);
  const [leverage, setLeverage] = useState([1]);
  const [filledOrders, setFilledOrders] = useState([]);
  const [openPositions, setOpenPositions] = useState([]);
  const [isPopupVisible, setIsPopupVisible] = useState(false);
  const [activeTable, setActiveTable] = useState('openPositions');
  const [openOrders, setOpenOrders] = useState([]);
  const [wethPrice, setWethPrice] = useState(0);
  const [wbtcPrice, setWbtcPrice] = useState(0);
  const [slippage, setSlippage] = useState(0.05);
  const [closeRatio, setCloseRatio] = useState(1);
  const zeroHex32bytes = '0x0000000000000000000000000000000000000000000000000000000000000000'
  const zeroAddress = '0x0000000000000000000000000000000000000000'
  const [allPrices, setAllPrices] = useState({})
  const { address, isConnected } = useAccount()
  const totalValue = price * quantity
  //const [decimal, setDecimal] = useState(8);
  const [fee, setFee] = useState(null);

  const resetInputs = () => {
    setOrderType('Buy');
    setPrice('');
    setQuantity('');
    setLeverage(1);
  }

  useEffect(() => {
    async function getPositions() {
      if (isConnected) {
        let positions = []

        for (const token in TOKENS) {
          //-for (let isLong = 0; isLong < 2; isLong++) {
            let decimal = 8; 
            if (TOKENS[token] === WBTC_ARB) {
              decimal = 8;
            } else if (TOKENS[token] === WETH_ARB) {
              decimal = 18;
            }  
        
          let result = await getPosition(address, TOKENS[token], TOKENS[token], 1);
          if (result?.at(0) > 0) {
            positions.push({
              orderType: true ? 'Buy' : 'Sell',
              selectedAsset: token,
              quantity: Number(result[4]) / 10 ** decimal,
              price: Number(result[2]) / 10 ** DECIMALS_GMX,
              totalValue: Number(result[0]) / 10 ** DECIMALS_GMX,
              collateral: Number(result[1]) / 10 ** DECIMALS_GMX,
              quantityWei: result[0],
              //size: result[0]
            })
          }

          result = await getPosition(address, USDC, TOKENS[token], 0);
          if (result?.at(0)   > 0) {
            positions.push({
              orderType: 'Sell',
              selectedAsset: token,
              quantity: Number(result[4]) / 10 ** decimal,
              price: Number(result[2]) / 10 ** DECIMALS_GMX,
              totalValue: Number(result[0]) / 10 ** DECIMALS_GMX,
              collateral: Number(result[1]) / 10 ** DECIMALS_GMX,
              quantityWei: result[0],
              //size: result[0]
            })
          }
          //-}
        }

        console.log(`position length: ${positions.length}`);
        setOpenPositions(positions);
        console.log(openPositions)


      }
    }
    async function getPrices() {
      const prices = await fetchGMXPrices();
      setWethPrice(prices[WETH_ARB]);
      setWbtcPrice(prices[WBTC_ARB]);
      if (prices) {
        Object.keys(prices).forEach((sym) => {
          prices[sym] = parseInt(prices[sym]) / 10 ** DECIMALS_GMX
        })
        setAllPrices(prices)
        if (WBTC_ARB in prices && selectedAsset === 'BTC') {
          setPrice(Math.round(parseInt(prices[WBTC_ARB]) * 100) / 100);
        } else if (WETH_ARB in prices && selectedAsset === 'ETH') {
          setPrice(Math.round(parseInt(prices[WETH_ARB]) * 100) / 100);
        } else {
          console.log("not found ")
        }
      }
    }
    getPrices();
    getPositions();

  }, [selectedAsset, isConnected]);

  function handleAssetSelect(asset) {
    setSelectedAsset(asset);
  }

  function handleExecuteTrade() {
    setIsPopupVisible(true);  
  }

  const handlePopupClose = () => {
    setIsPopupVisible(false);
  };

  const handleTradeConfirm = (order) => {
    setOpenOrders([...openOrders, order]);
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    const order = {
      orderType,
      selectedAsset,
      quantity,
      price,
      totalValue,
    };
    handleTradeConfirm(order);
    setIsPopupVisible(false);
    console.log(order);
  };

  const placeLeveragedOrder = async () => {
    const approvalAmount = BigInt(10 ** 70)
    const isLong = orderType === 'Buy'
    const acceptablePrice = isLong ? price * (1 + slippage) : price * (1 - slippage)
    const usdAmount = quantity * price * leverage
    const inputAmount = Math.floor(quantity * 10 ** (selectedAsset === 'BTC' ? 8 : 18))
    const token = selectedAsset === 'BTC' ? WBTC_ARB : WETH_ARB
    const approvalEntity = ROUTER
    //0xaBBc5F99639c9B6bCb58544ddf04EFA6802F4064
    if (isConnected) {
      if ((await isPluginApproved(address)) === false) {
        await routerPluginApproval()
      }
      console.log(`allowance: ${await allowance(token, address, approvalEntity)}, token: ${token} approvalEntity: ${approvalEntity}`)
      if (await allowance(token, address, approvalEntity) < inputAmount) {
        await approve(token, approvalEntity, approvalAmount);
        console.log("approved")
      } else {
        console.log("already approved")
      }

      //await createIncreasePositionEth(token, inputAmount, usdAmount, acceptablePrice, isLong)
      await createIncreasePosition(token, inputAmount, usdAmount, acceptablePrice, isLong)
    } else {
      console.log("not connected")
    }
  }

  const historicalTrades = [
    // define historical trades here...
  ];

  const handleTableChange = (event) => {
    setActiveTable(event.target.value);
  };

  const closePosition = async (orderType, token, amount) => {
    const ratio = Math.min(Math.max(closeRatio, .01))
    const isLong = orderType === 'Buy'
    amount = BigInt(amount) * BigInt(ratio     * 100) / 100n
    
    const currentPrice = allPrices[TOKENS[token]]
    const acceptablePrice = isLong ? currentPrice * (1 - slippage) : currentPrice * (1 + slippage)
    createDecreasePosition(address, TOKENS[token], amount, acceptablePrice, isLong)
  };

  //const refresh = () => {
  //  getPositions();
  //};

  const help = () => {
    setIsPopupVisible(true);
  };

  const renderTable = () => {
    if (activeTable === 'openOrders') {
      return (
        <table>
        </table>
      );
    } else if (activeTable === 'openPositions') {
      return (
        <table>
        </table>
      );
    } else if (activeTable === 'historicalTrades') {
      return (
        <table>
        </table>
      );
    }
  };

  return (
    <div className='optionsPage'>
      <div style={{ display: 'flex', flexDirection: 'column' }}>     
      </div>
      <div className='tradeBoxFutures' style={{ textAlign: 'center'}}>
        <div>
          <h1>Perpetual Futures</h1>
        </div>

        <div style={{ textAlign: 'left', paddingLeft: '50px' }}>
          {['BTC', 'ETH'].map(asset => (
            <button className='smallButton' key={asset} onClick={() => handleAssetSelect(asset)}>{asset}</button>
          ))}
        </div>
        <TradingViewWidget selectedAsset={selectedAsset} />
        <br></br>
      </div>
      <div className='tradeBox2' style={{ width: '300px', textAlign:'center' }} >
        <h1 style={{ marginBottom: '0' }}>Trade</h1>
        <div className="tradeDetails">
          <h3>Asset: {selectedAsset} <br></br><br></br>
            Price: {price}<br></br>
          </h3>
          <br></br>
          <div>
            <label htmlFor="orderType" >Buy/Sell:</label>
            <select value={orderType} onChange={e => setOrderType(e.target.value)}>
              <option value="Buy">Buy</option>
              <option value="Sell">Sell</option>
            </select>
          </div>
          <div>
            <label htmlFor="quantity" >Amount of Collateral to use:</label>
            <input type="number" value={quantity} onChange={e => setQuantity(e.target.value)} />
          </div>
          <div>
            <label htmlFor="price" >Price:</label>
            <input type="number" value={price} onChange={e => setPrice(e.target.value)} />
          </div>
          <div>
            <label htmlFor="leverage" >Leverage:</label>
            <input type="number" value={leverage} onChange={e => setLeverage(e.target.value)} />
          </div>
        </div>
        <div>
          <p style={{ textAlign: 'left' }}>Quantity to Trade: {(quantity * leverage).toFixed(4)} {selectedAsset}
            <br></br>
            Total $ Amount: ${(totalValue * leverage).toLocaleString()}
            <br></br>
            Value of Collateral: ${(totalValue).toLocaleString()}
          </p>
        </div>
        <div style={{ textAlign: 'left' }}>{orderType} {(quantity * leverage).toLocaleString()} {selectedAsset} Perpetual Futures at ${(price).toLocaleString()} for total value of ${(totalValue * leverage).toLocaleString()}.</div>
        <div>
          <button className="swapButton" onClick={placeLeveragedOrder}>Execute Trade</button>
        </div>
        <div>
          <button type="button" className='smallButton' onClick={resetInputs}>Reset Trade Details</button>
          <button type="button" onClick={ help } className='smallButton'>Instructions</button>
        </div>
        <br></br>
        {isPopupVisible && (
          <div className='popup' style={{ height: '600px'}}>
            <h2>Instructions</h2>
            <p style={{ textAlign: 'left' }}>
            These futures are sourced from GMX.<br></br><br></br>
            1. Please Connect Wallet to Arbitrum.<br></br>
            2. In the Perpetual Futures Box, choose Bitcoin or Ethereum.<br></br>
            3. Price is given by GMX.  Currently trigger orders (limit, stop-loss) are not available.<br></br>
            4. When Buying, WBTC is required to buy BTC and WETH is required to buy ETH.<br></br>
            5. Leverage is initially set at 1 (no leverage) with maximum leverage of 100X.<br></br>
            6. Sell = Short.  To short, you will require USDC.e for collateral (note this is NOT USDC).  To import the USDC.e 
            token address, please check Arbiscan.<br></br>
            7. To unwind an existing position, go to the Positions box at the bottom and choose your Close Ratio.  The Close Ratio
            sets how much of your position you wish to close out, with 1 equal to 100%.
            Click the Close Positions button to close out your trade.<br></br>
            8. You may double check your positions at GMX.io
            <br></br><br></br>
            Akasha Markets is currently in Alpha and is FREE TO USE, however charges from GMX and Metamask (paid in ETH) do apply.<br></br>
            </p>
            <br></br>
            <button type="button" onClick={handlePopupClose} className='smallButton'>Close</button>
          </div>
        )}
      </div>
      <div className='orders' style={{ textAlign: 'center'}}>
        <h1 style={{ marginBottom: '0' }}>Positions</h1>
        <div className="dropdown" style={{ textAlign: 'left' }}>
          Displaying: &nbsp;
          <select value={activeTable} onChange={handleTableChange}>
            <option value='openOrders'>Outstanding Orders</option>
            <option value='openPositions'>Current Positions</option>
            <option value='historicalTrades'>Historical Trades</option>
          </select>
          <div style={{ display:'flex', alignItems:'center', justifyContent:'space-between'}}>
            <div>
              <label>Close Ratio:  </label>
              <input type="number" value={closeRatio} onChange={e => setCloseRatio(e.target.value)} />
            </div>
          </div>
        </div>
        <br></br>
        {renderTable()}

        <div className='table'>
          <table>
            <thead>
              <tr>
                <th style={{ width: '7%' }}>Buy/Sell</th>
                <th style={{ width: '9%' }}>Asset</th>
                <th style={{ width: '9%' }}>Quantity</th>
                <th style={{ width: '9%' }}>Price</th>
                <th style={{ width: '9%' }}>Value</th>
                <th style={{ width: '9% '}}>Collateral</th>
                <th style={{ width: '9% '}}>Current Value</th>
                <th style={{ width: '9% '}}>Est P&L (excl Fees)</th>
                <th style={{ width: '9%' }}>Close</th>
              </tr>
            </thead>
            <tbody>
              {activeTable === 'openOrders' && openOrders.map((order, index) => (
                <tr key={index}>
                  <td>{order.orderType}</td>
                  <td>{order.selectedAsset}</td>
                  <td>{order.quantity}</td>
                  <td>{order.price}</td>
                  <td>{order.totalValue}</td>
                </tr>
              ))}
              {activeTable === 'openPositions' && openPositions.map((position, index) => (
                <tr key={index}>
                  <td>{position.orderType}</td>
                  <td>{position.selectedAsset}</td>
                  <td>{position.quantity.toFixed(4)}</td>
                  <td>{position.price.toFixed(0)}</td>
                  <td>${position.totalValue.toFixed(2)}</td>
                  <td>${position.collateral.toFixed(2)}</td>
                  <td>${new Intl.NumberFormat('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 }).format(((position.selectedAsset === 'WBTC' ? wbtcPrice : (position.selectedAsset === 'WETH' ? wethPrice : 0)) * position.quantity / (10 ** 30)).toString())}</td>
                  <td>
                      ${
                        position.orderType === 'Buy'
                          ? new Intl.NumberFormat('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 }).format(((position.selectedAsset === 'WBTC' ? wbtcPrice : (position.selectedAsset === 'WETH' ? wethPrice : 0)) * position.quantity / (10 ** 30) - position.totalValue).toString())
                          : new Intl.NumberFormat('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 }).format((position.totalValue - ((position.selectedAsset === 'WBTC' ? wbtcPrice : (position.selectedAsset === 'WETH' ? wethPrice : 0)) * position.quantity / (10 ** 30))).toString())
                      }
                  </td>
                  <td><button className='smallButton' onClick={() => closePosition(position.orderType, position.selectedAsset, position.quantityWei)}>Close Position33</button></td>
                </tr>
              ))}
              {activeTable === 'historicalTrades' && historicalTrades.map((trade, index) => (
                <tr key={index}>
                  <td>{trade.orderType}</td>
                  <td>{trade.selectedAsset}</td>
                  <td>{trade.quantity}</td>
                  <td>{trade.price}</td>
                  <td>{trade.totalValue}</td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>
    </div >
  );
}

export default Futures
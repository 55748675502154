import React, { useState, useEffect, useRef } from "react";
import "../../App.css";
import { useAccount } from 'wagmi'

//import from futures.js 
import {
  //createIncreasePositionEth,
  createIncreasePosition,
  routerPluginApproval,
  isPluginApproved,
  getPosition,
  createDecreasePosition,
  approve,
  allowance
} from "../../gmxChainInfo"
import { fetchGMXPrices, WBTC_ARB, WETH_ARB, DECIMALS, POSITION_ROUTER_ARBITRUM, ROUTER, USDC } from "../../utils";

//import from options.js; approve renamed as approve1
import {
  getCurrentBoards,
  getSpotPrice,
  getPricingParams,
  getLiveBoards,
  getLiquidity,
  getInterestRate,
  getVarianceFeeParams,
  fetchLiveBoards,
  createOptionContract,
  getOwnerPositions,
  closePosition,
  getMinCollateral,
  getOptionType,
  hasAllowance,
  approve1
} from "../../lyraConnector.js";
import { getOptionPrice } from "../../optionCalcs.js";
import { getQuoteIteration } from "../../quote.js"
import { getQuotesForBoards } from "../../retrieveData.js"

//from futures.js
const TOKENS = { 'WETH': WETH_ARB, 'WBTC': WBTC_ARB }
const DECIMALS_GMX = 30

//from options.js
const UNIT = BigInt(10 ** 18)
const ETH_MARKET = "0x919E5e0C096002cb8a21397D724C4e3EbE77bC15"
const dateFormat = { day: 'numeric', month: 'short', year: 'numeric' };

function ProtectivePutBuyPopup({ selectedAsset, onClose }) {
  //from futures.js
  //const [selectedAsset, setSelectedAsset] = useState('BTC');
  const [priceData, setPriceData] = useState([]);
  const [orderType, setOrderType] = useState('Buy');
  const [quantity, setQuantity] = useState([]);
  const [price, setPrice] = useState([]);
  const [leverage, setLeverage] = useState([1]);
  const [filledOrders, setFilledOrders] = useState([]);
  const [openPositions, setOpenPositions] = useState([]);
  const [isPopupVisible, setIsPopupVisible] = useState(false);
  const [activeTable, setActiveTable] = useState('openPositions');
  const [openOrders, setOpenOrders] = useState([]);
  const [wethPrice, setWethPrice] = useState(0);
  const [wbtcPrice, setWbtcPrice] = useState(0);
  const [slippage, setSlippage] = useState(0.05);
  const [closeRatio, setCloseRatio] = useState(0.5);
  const zeroHex32bytes = '0x0000000000000000000000000000000000000000000000000000000000000000'
  const zeroAddress = '0x0000000000000000000000000000000000000000'
  const [allPrices, setAllPrices] = useState({})
  const { address, isConnected } = useAccount()
  const totalValue = price * quantity
  const resetInputs = () => {
    setOrderType('Buy');
    setPrice('');
    setQuantity('');
    setLeverage(1);
  }

  //from options.js
  const [asset, setAsset] = useState(selectedAsset);
  const [price1, setPrice1] = useState("");
  const [expiry, setExpiry] = useState("");
  const [strike, setStrike] = useState("");
  const [optionType, setOptionType] = useState("Put");
  const [buyOrSell, setBuyOrSell] = useState("Sell");
  const [options, setOptions] = useState([])
  const [expiries, setExpiries] = useState(["All"])
  const [newOrder, setNewOrder] = useState(false)
  const [collateralRequired, setCollateralRequired] = useState(0n)
  const [numTradesCompleted, setNumtradesCompleted] = useState(0)
  const currentExpiryFilter = useRef(new Date(0))
  const totalValueOptions = price1 * quantity

  useEffect(() => {
    async function getPositions() {
      if (isConnected) {
        let positions = []


        for (const token in TOKENS) {
          //-for (let isLong = 0; isLong < 2; isLong++) {
          let result = await getPosition(address, TOKENS[token], TOKENS[token], 1);
          if (result && result[0] > 0) {
            positions.push({
              orderType: true ? 'Buy' : 'Sell',
              selectedAsset: token,
              quantity: Number(result[4]) / 10 ** 8,
              price: Number(result[2]) / 10 ** DECIMALS_GMX,
              totalValue: Number(result[0]) / 10 ** DECIMALS_GMX,
              size: result[0]
            })
          }

          result = await getPosition(address, USDC, TOKENS[token], 0);
          if (result && result[0] > 0) {
            positions.push({
              orderType: 'Sell',
              selectedAsset: token,
              quantity: Number(result[0]) / 10 ** DECIMALS_GMX,
              price: Number(result[2]) / 10 ** DECIMALS_GMX,
              totalValue: Number(result[0]) / 10 ** DECIMALS_GMX,
              size: result[0]
            })
          }
          //-}
        }

        console.log(`position length: ${positions.length}`);
        setOpenPositions(positions);
        console.log(openPositions)

      }
    }
    async function getPrices() {
      const prices = await fetchGMXPrices();
      setWbtcPrice(prices[WBTC_ARB]);
      if (prices) {
        Object.keys(prices).forEach((sym) => {
          prices[sym] = parseInt(prices[sym]) / 10 ** DECIMALS_GMX
        })
        setAllPrices(prices)
        if (WBTC_ARB in prices && selectedAsset === 'Bitcoin') {
          setPrice(Math.round(parseInt(prices[WBTC_ARB]) * 100) / 100);
        } else if (WETH_ARB in prices && selectedAsset === 'Ethereum') {
          setPrice(Math.round(parseInt(prices[WETH_ARB]) * 100) / 100);
        } else {
          console.log("not found ")
        }
      }

    }
    getPrices();
    getPositions();

  }, [selectedAsset, isConnected]);

  //function handleExecuteTrade() {
  //  setIsPopupVisible(true);
    // ...
  //}

  const handlePopupClose = () => {
    setIsPopupVisible(false);
  };

  const handleTradeConfirm = (order) => {
    setOpenOrders([...openOrders, order]);
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    const order = {
      orderType,
      selectedAsset,
      quantity,
      price,
      totalValue,
    };
    handleTradeConfirm(order);
    setIsPopupVisible(false);
    console.log(order);
  };

  const placeLeveragedOrder = async () => {
    const approvalAmount = BigInt(10 ** 70)
    const isLong = orderType === 'Buy'
    const acceptablePrice = isLong ? price * (1 + slippage) : price * (1 - slippage)
    const usdAmount = quantity * price * leverage
    const inputAmount = Math.floor(quantity * 10 ** (selectedAsset === 'Bitcoin' ? 8 : 18))
    const token = selectedAsset === 'Bitcoin' ? WBTC_ARB : WETH_ARB
    const approvalEntity = ROUTER
    //0xaBBc5F99639c9B6bCb58544ddf04EFA6802F4064
    if (isConnected) {
      if ((await isPluginApproved(address)) === false) {
        await routerPluginApproval()
      }
      console.log(`allowance: ${await allowance(token, address, approvalEntity)}, token: ${token} approvalEntity: ${approvalEntity}`)
      if (await allowance(token, address, approvalEntity) < inputAmount) {
        await approve(token, approvalEntity, approvalAmount);
        console.log("approved")
      } else {
        console.log("already approved")
      }

      //await createIncreasePositionEth(token, inputAmount, usdAmount, acceptablePrice, isLong)
      await createIncreasePosition(token, inputAmount, usdAmount, acceptablePrice, isLong)
    } else {
      console.log("not connected")
    }
  }

  //const openPositions = [
  // define open positions here...
  //];

//  const historicalTrades = [
    // define historical trades here...
//  ];

//  const handleTableChange = (event) => {
//    setActiveTable(event.target.value);
//  };

  const closePosition = async (orderType, token, amount) => {
    const ratio = Math.min(Math.max(closeRatio, .01))
    const isLong = orderType === 'Buy'
    amount = amount * BigInt(ratio * 100) / 100n
    const currentPrice = allPrices[TOKENS[token]]
    const acceptablePrice = isLong ? currentPrice * (1 - slippage) : currentPrice * (1 + slippage)

    createDecreasePosition(address, TOKENS[token], amount, acceptablePrice, isLong)
  }

  //for options:

  useEffect(() => {
    getAllQuotes();
  }, [asset])

  const handleAssetChange = async (_asset) => {
    setAsset(_asset);
  };

  const handleExpiryChange = (expiry) => {
    console.log(`expiry: ${typeof expiry}`)
    console.log(`currentExpiryFilter: ${JSON.stringify(typeof currentExpiryFilter.current.value)}`)
    setExpiry(expiry);
  };

  const handleQuantityChange = (quantity) => {
    setQuantity(quantity);
  };

  const getAllQuotes = async () => {
    const size = 1000000n;
    const market = asset === "Bitcoin"
    //console.log(`market: ${market} ${asset}`)
    const quotes = await getQuotesForBoards(size, asset === "Ethereum" ? "ETH" : "BTC")
    //quotes.forEach(quote => {
    ////   console.log(JSON.stringify(quote, (key, value) => typeof value === 'bigint' ? value.toString() : value))
    //})
    const displayQuotes = []
    for (let i = 0; i < quotes.length; i++) {
      for (let j = 0; j < quotes[i].strikes.length; j++) {
        displayQuotes.push({
          exercisePrice: Number(quotes[i].strikes[j].strikePrice / UNIT),
          strikeId: quotes[i].strikes[j].strikeId,
          putAsk: (Number(quotes[i].strikes[j].putAsk) / Number(UNIT)).toFixed(2),
          expiry: (new Date(Number(quotes[i].expiry) * 1000))
        })

      }
    }
    displayQuotes.forEach(quote => console.log(quote.expiry))
    let expirations = displayQuotes.map(quote => String(quote.expiry.getTime()))
    if (expirations.length > 0) {
      const newExpirations = [...new Set(expirations)]
      expirations.forEach(expiration => console.log(`expiration: ${Number(expiration)}`))
      newExpirations.unshift("All")
      setExpiries(newExpirations)
    }
    setOptions(displayQuotes);
  }

  const getPositions = async (address) => {
    const markets = await getOwnerPositions(address)
    console.log(`positions: ${JSON.stringify(markets, (key, value) => typeof value === 'bigint' ? value.toString() : value)}`)
    const displayPositions = []
    markets.forEach(market => {
      market.positions.forEach(position => {
        displayPositions.push({
          buyOrSell: position.optionType === 0 || position.optionType === 1 ? 'Buy' : 'Sell',
          asset: market.market === ETH_MARKET ? 'ETH' : 'BTC',
          optionType: position.optionType === 0 || position.optionType === 3 ? 'Call' : 'Put',
          strike: options.filter(option => option.strikeId === position.strikeId)?.[0]?.exercisePrice,
          quantity: Number(position.amount) / Number(UNIT),
          price: position.price,
          totalValue: -99,
          expiry: options.filter(option => option.strikeId === position.strikeId)?.[0]?.expiry?.toLocaleString(),
          optionTypeNum: position.optionType,
          positionId: position.positionId,
          strikeId: position.strikeId
          //positionId
        })
      })
    })
    return displayPositions;
  }

  const setNewOrderView = async (strikeId, isCall, isBuy, price1) => {
    setNewOrder({ strikeId, isCall, isBuy })
    setBuyOrSell(isBuy ? 'Buy' : 'Sell')
    setOptionType(isCall ? 'Call' : 'Put')
    setStrike(options.filter(option => option.strikeId === strikeId)?.[0]?.exercisePrice)
    setPrice1(price1);

    const optionType = getOptionType(isCall, isBuy)
    const strikePrice = BigInt(options.filter(option => option.strikeId === strikeId)?.[0]?.exercisePrice) * UNIT;
    const expiry = options.filter(option => option.strikeId === strikeId)?.[0]?.expiry.getTime();
    const spotPrice = await getSpotPrice()
    const amount = BigInt(quantity * Number(UNIT));
    const minCollateral = await getMinCollateral(optionType, strikePrice, expiry - Date.now(), spotPrice, amount)
    setCollateralRequired(minCollateral)
  }

  const handleTradeExecution = async () => {
    const { strikeId, isCall, isBuy } = newOrder
    const market = asset === "Ethereum" ? "ETH" : "BTC"
    /*
    const optionType = getOptionType(isCall, isBuy)
    const strikePrice = BigInt(options.filter(option => option.strikeId === strikeId)?.[0]?.exercisePrice) * UNIT;
    const spotPrice = await getSpotPrice()
    const amount = BigInt(quantity * Number(UNIT));
    const minCollateral = await getMinCollateral(optionType, strikePrice, expiry - Date.now(), spotPrice, amount)
    */
    if ((await hasAllowance(market, address)) === false) {
      console.log("approve")
      await approve1(market)
    }

    await createOptionContract(BigInt(quantity * leverage * Number(UNIT)), strikeId, isCall, isBuy, collateralRequired, market, expiry, address)
    setNumtradesCompleted(numTradesCompleted + 1)
    /*
    console.log(`strikeId: ${strikeId}`)
    setOpenOrders(await getPositions(address))
    setIsPopupVisible(false);
    */
  };

  
  const executeFuturesOptions = () => {
      placeLeveragedOrder();
      handleTradeExecution();
    };

   return (
        <div style={{display: 'flex', flexDirection: "column", height: "800px", overflowY: 'scroll', overflowX: 'hidden', textAlign: 'center'}}>
          <h1 style={{textAlign:"center", marginTop:'0px', marginBottom:'0px'}}>{selectedAsset} Protective Put</h1>
          <h3 style={{color:'goldenrod', marginBottom:'0px', textAlign:'left'}}>Long Futures</h3>
          <div>
            <label htmlFor="price" >Price:</label>
            <input type="number" value={price} onChange={e => setPrice(e.target.value)} />
            <label htmlFor="quantity" >Amount of Collateral to use:</label>
            <input type="number" value={quantity} onChange={e => setQuantity(e.target.value)} />
            <label htmlFor="leverage" >Leverage:</label>
            <input type="number" value={leverage} onChange={e => setLeverage(e.target.value)}/>
            <p style={{ textAlign: 'left' }}>Quantity to Trade: {(quantity * leverage).toFixed(4)} {selectedAsset}
            <br></br>
            Value of Collateral: ${(totalValue).toFixed(0)}
            <br></br>
            Total $ Amount: ${(totalValue * leverage).toFixed(0)}
            </p>
          </div>
          <h3 style={{color:'goldenrod', marginTop:'-10px', marginBottom:'0px', textAlign:'left'}}>Long Put</h3>
          <div>
          # of contracts:  {(quantity * leverage).toFixed(4)} Puts
          <br></br>
          <label htmlFor="expiry" style={{ textAlign: 'left' }}>Expiry:</label>
          <select
              id="expiry"
              name="expiry"
              ref={currentExpiryFilter}
              onChange={(event) => handleExpiryChange(event.target.value)}
          >
              {expiries.map((expiry) => <option value={expiry}> {expiry === 'All' ? expiry : (new Date(Number(expiry))).toLocaleString('us-EN', dateFormat)}</option>)}
          </select>
          <br></br>
          <table>
            <thead>
              <tr>
                <th>Put Ask</th>
                <th style={{ color: 'goldenrod' }}>Strike Price</th>
                <th>Expiry</th>
              </tr>
            </thead>
            <tbody>
              {options
                .filter(option => currentExpiryFilter.current.value === "All" || option.expiry.getTime() === (new Date(Number(currentExpiryFilter.current.value))).getTime())
                .map((option) => (
                  <tr>
                    {option.putAsk && <td>{<button className="smallButton" onClick={() => setNewOrderView(option.strikeId, false, true, option.putAsk)}> {option.putAsk}</button>} </td>}
                    <td style={{ color: 'goldenrod' }}>{option.exercisePrice}</td>
                    {option.expiry && <td>{`${option.expiry.toLocaleString('us-EN', dateFormat)}`}</td>}
                  </tr>
                ))}
            </tbody>
          </table>
          <br></br>
          Option Cost: ${(totalValueOptions * leverage).toLocaleString()}
          <br></br>
          Strike Price: {strike}
          <br></br>
          Collateral Required:
          </div>
          <h3 style={{color:"goldenrod", marginTop:'-5px', marginBottom:'0px', textAlign: 'left'}}>Net Cost: ${((totalValueOptions * leverage) + totalValue).toFixed(0)}  (Futures Collateral plus Options cost)</h3>
          <button className="swapButton" onClick={executeFuturesOptions} style={{marginBottom:'0px'}}>Execute Trade</button>
          <div>
          <button className="smallButton" onClick={onClose}>Close</button>
          <button className="smallButton">Instructions</button>
          </div>
        </div>
  );
}

export default ProtectivePutBuyPopup;
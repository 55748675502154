import React from 'react';

function BearPutSpreadExplanation({ onClose }) {
  return (
    <div style={{display: 'flex', flexDirection: "column", height: "800px", overflowY: 'scroll', overflowX: 'hidden', textAlign: 'center'}}>
      <h2>Bear Put Spread</h2>
      <p style={{textAlign: 'left'}}>
      A bear put spread is an options strategy that involves buying a put 
      option with a higher strike price and simultaneously selling a put 
      option with a lower strike price. Both options have the same expiration 
      date and underlying asset. <br></br><br></br>
      This strategy is utilized when an investor 
      has a moderately bearish outlook on the underlying asset but wants to 
      limit their upfront cost and potential downside risk.<br></br><br></br>
      Here's how a bear put spread works:<br></br><br></br>
      1. Buy Higher Strike (In-The-Money) Put Option: The investor buys a put option with a 
      higher strike price. This option gives the investor the right to sell 
      the underlying asset at the specified strike price, known as the "long 
      put."<br></br><br></br>
      2. Sell Lower Strike (Out-The-Money) Put Option: Simultaneously, the investor sells a 
      put option with a lower strike price. This option obligates the investor 
      to buy the underlying asset at the specified strike price, known as the 
      "short put."<br></br><br></br>
      2. Same Expiration Date: Both the long put and the short put have the 
      same expiration date.<br></br><br></br>
      When executing a bearish put spread, the premium received from selling 
      the lower-strike put option is usually lower than the premium paid for 
      buying the higher-strike put option. This difference in premiums results 
      in a net debit or premium paid by the investor.  The net premium paid 
      represents the maximum potential loss for the investor. If the price of 
      the underlying asset remains above the higher strike price at expiration, 
      both options may expire worthless, resulting in a loss equal to the net 
      premium paid.
      </p>
      <button className="smallButton" onClick={onClose}>Close</button>
    </div>
  );
}

export default BearPutSpreadExplanation;
export const MAINNET = 56;
export const TESTNET = 97;
export const ETH_MAINNET = 1;
export const AVALANCHE = 43114;
export const AVALANCHE_FUJI = 43113;
export const ARBITRUM = 42161;
export const ARBITRUM_TESTNET = 421611;
export const FEES_HIGH_BPS = 50;
export const WBTC_ARB = "0x2f2a2543B76A4166549F7aaB2e75Bef0aefC5B0f";
export const WETH_ARB = "0x82aF49447D8a07e3bd95BD0d56f35241523fBab1";
export const CHAINLINK_TOKEN_ARB = "0xf97f4df75117a78c1A5a0DBb814Af92458539FB4"
export const UNISWAP_TOKEN_ARB = "0xFa7F8980b0f1E64A2062791cc3b0871572f1F7f0"
export const GMX_URL_ARB = "https://gmx-server-mainnet.uw.r.appspot.com"
export const POSITION_ROUTER_ARBITRUM = "0xb87a436B93fFE9D75c5cFA7bAcFff96430b09868"
export const ROUTER = "0xaBBc5F99639c9B6bCb58544ddf04EFA6802F4064"
export const USDC = "0xFF970A61A04b1cA14834A43f5dE4533eBDDB5CC8"

export const ARBITRUM_ADDRESSES = {
  PositionRouter: "0xb87a436B93fFE9D75c5cFA7bAcFff96430b09868",
  OrderBook: "0x09f77E8A13De9a35a7231028187e9fD5DB8a2ACB",
  OrderBookReader: "0xa27C20A7CF0e1C68C0460706bB674f98F362Bc21",
  Vault: "0x489ee077994B6658eAfA855C308275EAd8097C4A"
}



const BACKEND_URLS = {
  default: "https://gmx-server-mainnet.uw.r.appspot.com",

  [MAINNET]: "https://gambit-server-staging.uc.r.appspot.com",
  [ARBITRUM_TESTNET]: "https://gambit-server-devnet.uc.r.appspot.com",
  [ARBITRUM]: "https://gmx-server-mainnet.uw.r.appspot.com",
  [AVALANCHE]: "https://gmx-avax-server.uc.r.appspot.com",
};

export const fetchGMXPrices = async () => {
  const response = await fetch(`${GMX_URL_ARB}/prices`);
  const data = await response.json();
  return data;
}

export const fetchGMXOrders = async (account) => {
  const response = await fetch(`${GMX_URL_ARB}/orders_indices?account=${account}`);
  const data = await response.json();
  return data;
}

export const DECIMALS = {
  [WBTC_ARB]: 8,
  [WETH_ARB]: 18,
};

export const fetchCurrentOrders = async () => {
  const response = await fetch(`${GMX_URL_ARB}/orders`);
  const data = await response.json();
  return data;
}




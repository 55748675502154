import { useState, useEffect } from 'react';
import { useAccount, useConnect, useDisconnect } from 'wagmi';
import { InjectedConnector } from 'wagmi/connectors/injected';

export function Profile() {
  const { address, isConnected } = useAccount();
  const { connect } = useConnect({
    connector: new InjectedConnector(),
  });
  const { disconnect } = useDisconnect();
  const [selectedNetwork, setSelectedNetwork] = useState("0xa4b1");

  const handleConnect = () => {
    let connector;
    if (selectedNetwork === "0xa4b1") {
      // Connect to Arbitrum
      connector = new InjectedConnector({ supportedChainIds: [421611] });
    } else {
      // Connect to Ethereum (default)
      connector = new InjectedConnector();
    }
    connect({ connector });
  };

  useEffect(() => {
    if (isConnected) {
      // Perform network change logic here
      if (selectedNetwork === "0xa4b1") {
        // Change to Arbitrum
        window.ethereum.request({
          method: 'wallet_switchEthereumChain',
          params: [{ chainId: '0xa4b1' }],
        });
      } else {
        // Change to Ethereum
        window.ethereum.request({
          method: 'wallet_switchEthereumChain',
          params: [{ chainId: '0x1' }],
        });
      }
    }
  }, [isConnected, selectedNetwork]);

  return (
    <div>
      <div style={{ display: 'flex', alignItems: 'center' }}>
        <select
          className='headerSelect'
          value={selectedNetwork}
          onChange={(event) => setSelectedNetwork(event.target.value)}
          style={{marginRight: '10px'}}
        >
          <option value="0xa4b1">Arbitrum</option>
          <option value="0x1">Ethereum</option>
        </select>
        {isConnected && (
          <div>
            <button className='smallButton'>
              {address.slice(0, 4)}...{address.slice(38)}
            </button>
            <button className='smallButton' onClick={() => disconnect()}>
              Disconnect
            </button>
          </div>
        )}
        {!isConnected && (
          <button
            className='smallButton'
            style={{ width: '150px' }}
            onClick={handleConnect}
          >
            Connect Wallet
          </button>
        )}
      </div>
    </div>
  );
}
import React from 'react';
import "../App.css";

function Modal(props) {
  const { show, onClose, recommendation } = props;

  return (
    <div className={`modal ${show ? 'show' : ''}`}>
      <div className="modal-content" style={{display: "flex", flexDirection: "column", justifyContent:'center', alignItems:'center'}}>
        <h2>Trade Recommendation</h2>
        <p>{recommendation}</p>
        <button className='smallButton' onClick={onClose}>Close</button>
      </div>
    </div>
  );
}

export default Modal;
import React, { useState, useEffect } from "react";
import { useAccount } from 'wagmi'
import "../App.css";
import productsData from "./Products.json";
import CoveredCallPayoffDiagram from "./PayoffDiagrams/CoveredCall";
import ProtectivePutPayoffDiagram from "./PayoffDiagrams/ProtectivePut";
import LongStraddlePayoffDiagram from "./PayoffDiagrams/LongStraddle";
import ShortStraddlePayoffDiagram from "./PayoffDiagrams/ShortStraddle";
import BullCallSpreadPayoffDiagram from "./PayoffDiagrams/BullishCallSpread";
import BearCallSpreadPayoffDiagram from "./PayoffDiagrams/BearishCallSpread";
import BullPutSpreadPayoffDiagram from "./PayoffDiagrams/BullishPutSpread";
import BearPutSpreadPayoffDiagram from "./PayoffDiagrams/BearishPutSpread";
import CoveredCallBuyPopup from "./StructuredProducts/CoveredCall";
import ProtectivePutBuyPopup from "./StructuredProducts/ProtectivePut";
import LongStraddleBuyPopup from "./StructuredProducts/LongStraddle";
import ShortStraddleBuyPopup from './StructuredProducts/ShortStraddle';
import BullCallSpreadBuyPopup from './StructuredProducts/BullCallSpread';
import BearCallSpreadBuyPopup from './StructuredProducts/BearCallSpread';
import BullPutSpreadBuyPopup from './StructuredProducts/BullPutSpread';
import BearPutSpreadBuyPopup from './StructuredProducts/BearPutSpread';
import CoveredCallExplanation from "./Explanations/CoveredCall";
import ProtectivePutExplanation from "./Explanations/ProtectivePut";
import LongStraddleExplanation from "./Explanations/LongStraddle";
import ShortStraddleExplanation from "./Explanations/ShortStraddle";
import BullCallSpreadExplanation from './Explanations/BullCallSpread';
import BearCallSpreadExplanation from './Explanations/BearCallSpread';
import BullPutSpreadExplanation from './Explanations/BullPutSpread';
import BearPutSpreadExplanation from './Explanations/BearPutSpread';
import { getPosition } from "../gmxChainInfo"
import { fetchGMXPrices, WBTC_ARB, WETH_ARB, DECIMALS, POSITION_ROUTER_ARBITRUM, ROUTER, USDC } from "../utils";
const TOKENS = { 'WETH': WETH_ARB, 'WBTC': WBTC_ARB }
const DECIMALS_GMX = 30

function Available() {
  const [selectedAsset, setSelectedAsset] = useState("Ethereum");
  const [selectedProduct, setSelectedProduct] = useState(null);
  const [showPayoffPopup, setShowPayoffPopup] = useState(false);
  const [showBuyPopup, setShowBuyPopup] = useState(false); 
  const [showExplanation, setShowExplanation] = useState(false);
  const [products] = useState(productsData);
  const [activeTable, setActiveTable] = useState('openPositions');
  const [closeRatio, setCloseRatio] = useState(1);
  const [openPositions, setOpenPositions] = useState([]);
  const { address, isConnected } = useAccount()
  const handleAssetChange = (event) => {
    setSelectedAsset(event.target.value);
    setSelectedProduct(null);
    setShowPayoffPopup(false);
  };

  const handleShowPayoffClick = (product) => {
    setSelectedProduct(product);
    setShowPayoffPopup(true);
  };

  const handleHidePayoffClick = () => {
    setSelectedProduct(null);
    setShowPayoffPopup(false);
  };

  const handleShowBuyClick = (product) => {
    setSelectedProduct(product);
    setShowBuyPopup(true);
  };

  const handleHideBuyClick = () => {
    setShowBuyPopup(false);
  };

  const handleShowExplanationClick = (product) => {
    setSelectedProduct(product);
    setShowExplanation(true);
  };

  const handleHideExplanationClick = () => {
    setShowExplanation(false);
  };

  const filteredProducts = products.filter(
    (product) => product.asset === selectedAsset
  );

  const renderPayoffDiagram = () => {
    switch (selectedProduct.product) {
      case "Covered Call":
        return <CoveredCallPayoffDiagram payoffData={selectedProduct.payoffData} />;
      case "Protective Put":
        return <ProtectivePutPayoffDiagram payoffData={selectedProduct.payoffData} />;
      case "Long Straddle":
        return <LongStraddlePayoffDiagram payoffData={selectedProduct.payoffData} />;
      case "Short Straddle":
        return <ShortStraddlePayoffDiagram payoffData={selectedProduct.payoffData} />;
      case "Bullish Call Spread":
        return <BullCallSpreadPayoffDiagram payoffData={selectedProduct.payoffData} />;
      case "Bearish Call Spread":
        return <BearCallSpreadPayoffDiagram payoffData={selectedProduct.payoffData} />;
      case "Bullish Put Spread":
        return <BullPutSpreadPayoffDiagram payoffData={selectedProduct.payoffData} />;
      case "Bearish Put Spread":
        return <BearPutSpreadPayoffDiagram payoffData={selectedProduct.payoffData} />;
      default:
        return null;
    }
  };

  const handleTableChange = (event) => {
    setActiveTable(event.target.value);
    };

  const refresh = () => {
    getPosition();
    };

  //get futures positions
  useEffect(() => {
      async function getPositions() {
        if (isConnected) {
          let positions = []
  
          for (const token in TOKENS) {
            //-for (let isLong = 0; isLong < 2; isLong++) {
            let result = await getPosition(address, TOKENS[token], TOKENS[token], 1);
            if (result && result[0] > 0) {
              positions.push({
                orderType: true ? 'Buy' : 'Sell',
                selectedAsset: token,
                quantity: Number(result[4]) / 10 ** 8,
                price: Number(result[2]) / 10 ** DECIMALS_GMX,
                totalValue: Number(result[0]) / 10 ** DECIMALS_GMX,
                collateral: Number(result[1]) / 10 ** DECIMALS_GMX,
                size: result[0]
              })
            }
  
            result = await getPosition(address, USDC, TOKENS[token], 0);
            if (result && result[0] > 0) {
              positions.push({
                orderType: 'Sell',
                selectedAsset: token,
                quantity: Number(result[0]) / 10 ** DECIMALS_GMX,
                price: Number(result[2]) / 10 ** DECIMALS_GMX,
                totalValue: Number(result[0]) / 10 ** DECIMALS_GMX,
                collateral: Number(result[1]) / 10 ** DECIMALS_GMX,
                size: result[0]
              })
            }
          }
          console.log(`position length: ${positions.length}`);
          setOpenPositions(positions);
          console.log(openPositions)
        }
      }
      getPositions();
    }, [selectedAsset, isConnected]);  

  //get Options Positions    

  useEffect(() => {
    // fetch products data from products.json
    fetchProducts();
  }, []);

  const fetchProducts = async () => {
    try {
      const response = await fetch('Products.json');
      const data = await response.json();
      renderTableStructuredProducts(data);
    } catch (error) {
      console.log('Error fetching products:', error);
    }
  };

  const getBuyPopupComponent = (productType) => {
    switch (productType) {
      case 'Covered Call':
          return CoveredCallBuyPopup;
      case 'Protective Put':
          return ProtectivePutBuyPopup;
      case 'Long Straddle':
          return LongStraddleBuyPopup; 
      case 'Short Straddle':
          return ShortStraddleBuyPopup;
      case 'Bullish Call Spread':
          return BullCallSpreadBuyPopup;
      case 'Bearish Call Spread':
          return BearCallSpreadBuyPopup;
      case 'Bullish Put Spread':
          return BullPutSpreadBuyPopup;
      case 'Bearish Put Spread':
          return BearPutSpreadBuyPopup;   
      default: return null;
    }
  };

  const BuyPopupComponent = getBuyPopupComponent(selectedProduct?.product);

  const getExplanationComponent = (productType) => {
    switch (productType) {
      case 'Covered Call':
          return CoveredCallExplanation;
      case 'Protective Put':
          return ProtectivePutExplanation;
      case 'Long Straddle':
          return LongStraddleExplanation; 
      case 'Short Straddle':
          return ShortStraddleExplanation;
      case 'Bullish Call Spread':
          return BullCallSpreadExplanation;
      case 'Bearish Call Spread':
          return BearCallSpreadExplanation;
      case 'Bullish Put Spread':
          return BullPutSpreadExplanation;
      case 'Bearish Put Spread':
          return BearPutSpreadExplanation;   
      default: return null;
    }
  };

  const ExplanationComponent = getExplanationComponent(selectedProduct?.product);

  const renderTableStructuredProducts = () => {
      if (filteredProducts.length === 0) {
        return <p>No products available for the selected asset.</p>;
      };
      return (
        <table>
          <thead>
            <tr>
              <th style={{ width: "14%" }}>Product</th>
              <th style={{ width: "24%" }}>Composition</th>
              <th style={{ width: "16%" }}>Payoff Diagram</th>
              <th style={{ width: "9%" }}>Trade</th>
              <th style={{ width: "9%" }}>Description</th>
            </tr>
          </thead>
          <tbody>
            {filteredProducts.map((product, index) => (
              <tr key={index}>
                <td>{product.product}</td>
                <td>{product.description}</td>
                <td>
                {selectedProduct === product && showPayoffPopup ? (
                  <div className="popup1">
                  <div className="popup-inner1" style={{textAlign: 'right'}}>
                  {renderPayoffDiagram()}
                  <button onClick={handleHidePayoffClick}>Close</button>
                  </div>
                  </div>
                  ) : (
                  <button className="smallButton" onClick={() => handleShowPayoffClick(product)}>Show Payoff</button>
                )}
                </td>
                <td>
                {selectedProduct === product && showBuyPopup && BuyPopupComponent && (
                <div className="popup">
                <BuyPopupComponent product={selectedProduct} selectedAsset={selectedAsset} onClose={handleHideBuyClick} />
                </div>
                )}
                <button className="smallButton" onClick={() => handleShowBuyClick(product)}>Buy</button>
                </td>
                <td>
                {selectedProduct === product && showExplanation && ExplanationComponent && (
                <div className="popup">
                <ExplanationComponent product={selectedProduct} onClose={handleHideExplanationClick} />
                </div>
                )}
                <button className="smallButton" onClick={() => handleShowExplanationClick(product)}>Explanation</button>
                </td>
                </tr>
            ))}
          </tbody>
        </table>
      );
    };
  
    const renderTableFuturesPositions = () => {
      if (activeTable === 'openOrders') {
        return (
          <table>
          </table>
        );
      } else if (activeTable === 'openPositions') {
        return (
          <table>
          </table>
        );
      } else if (activeTable === 'historicalTrades') {
        return (
          <table>
          </table>
        );
      }
    };

    const renderTableOptionsPositions = () => {
      if (activeTable === 'openOrders') {
        return (
          <table>
          </table>
        );
      } else if (activeTable === 'openPositions') {
        return (
          <table>
          </table>
        );
      } else if (activeTable === 'historicalTrades') {
        return (
          <table>
          </table>
        );
      }
    };    

  return (
    <div style={{ display: 'flex', flexDirection: "column", alignItems: 'center', textAlign: 'center' }}>
    <div className="tradeBoxFutures">
      <h1>Investment Strategies</h1>
      <br></br>
      <p className="P1">
      Below are the strategies that are currently available: <br></br>
      <br></br>
      <label htmlFor="asset">Select an asset: </label>
      <select id="asset" value={selectedAsset} onChange={handleAssetChange}>
        <option value="Bitcoin">Bitcoin</option>
        <option value="Ethereum">Ethereum</option>
      </select>
      </p>
      {renderTableStructuredProducts()}
    </div>

    <div className='orders' style={{ textAlign: 'center'}}>
        <h1 style={{ marginBottom: '0' }}>Positions</h1>
        <div className="dropdown" style={{ textAlign: 'left' }}>
          Displaying: &nbsp;
          <select value={activeTable} onChange={handleTableChange}>
            <option value='openOrders'>Outstanding Orders</option>
            <option value='openPositions'>Current Positions</option>
            <option value='historicalTrades'>Historical Trades</option>
          </select>
          <div style={{ display:'flex', alignItems:'center', justifyContent:'space-between'}}>
            <div>
              <label>Close Ratio:  </label>
              <input type="number" value={closeRatio} onChange={e => setCloseRatio(e.target.value)} />
            </div>
            <button onClick={refresh} className="smallButton">Refresh Positions</button>
          </div>
        </div>
        <h3 style={{ textAlign:'left'}}>Futures</h3>
        {renderTableFuturesPositions()}
        <div className='table'>
          <table>
            <thead>
              <tr>
                <th style={{ width: '11%' }}>Buy/Sell</th>
                <th style={{ width: '11%' }}>Asset</th>
                <th style={{ width: '11%' }}>Quantity</th>
                <th style={{ width: '11%' }}>Price</th>
                <th style={{ width: '11%' }}>Value</th>
                <th style={{ width: '11% '}}>Collateral</th>
                <th style={{ width: '17%' }}>Close</th>
              </tr>
            </thead>
            <tbody>
              {activeTable === 'openOrders' && openOrders.map((order, index) => (
                <tr key={index}>
                  <td>{order.orderType}</td>
                  <td>{order.selectedAsset}</td>
                  <td>{order.quantity}</td>
                  <td>{order.price}</td>
                  <td>{order.totalValue}</td>
                </tr>
              ))}
              {activeTable === 'openPositions' && openPositions.map((position, index) => (
                <tr key={index}>
                  <td>{position.orderType}</td>
                  <td>{position.selectedAsset}</td>
                  <td>{position.quantity.toFixed(4)}</td>
                  <td>{position.price.toFixed(0)}</td>
                  <td>${position.totalValue.toFixed(2)}</td>
                  <td>${position.collateral.toFixed(2)}</td>
                  <td><button className='smallButton' onClick={() => closePosition(position.orderType, position.selectedAsset, position.size)}>Close Position</button></td>
                </tr>
              ))}
              {activeTable === 'historicalTrades' && historicalTrades.map((trade, index) => (
                <tr key={index}>
                  <td>{trade.orderType}</td>
                  <td>{trade.selectedAsset}</td>
                  <td>{trade.quantity}</td>
                  <td>{trade.price}</td>
                  <td>{trade.totalValue}</td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
        
        <h3 style={{ textAlign:'left'}}>Options</h3>
        {renderTableOptionsPositions()}
        <div className='table'>
          <table>
            <thead>
              <tr>
                <th style={{ width: '9%' }}>Buy/Sell</th>
                <th style={{ width: '9%' }}>Asset</th>
                <th style={{ width: '9%' }}>Option</th>
                <th style={{ width: '9%' }}>Strike</th>
                <th style={{ width: '9%' }}>Quantity</th>
                <th style={{ width: '11%' }}>Price</th>
                <th style={{ width: '11%' }}>Value</th>
                <th style={{ width: '20%' }}>Expiry</th>
                <th style={{ width: '17%' }}>Close</th>
              </tr>
            </thead>
            <tbody>
              {activeTable === 'openOrders' && openOrders.map((order, index) => (
                <tr key={index}>
                  <td>{order.buyOrSell}</td>
                  <td>{order.asset}</td>
                  <td>{order.optionType}</td>
                  <td>{order.strike}</td>
                  <td>{order.quantity}</td>
                  <td>{order.price}</td>
                  <td>{order.totalValue}</td>
                  <td>{order.expiry}</td>
                  <td> <button onClick={() => handleClosePosition(order.strikeId, order.positionId, BigInt((order.quantity) * Number(UNIT)), order.optionTypeNum)}> Close </button></td>
                </tr>
              ))}
              {activeTable === 'openPositions' && openPositions.map((position, index) => (
                <tr key={index}>
                  <td>{position.buyOrSell}</td>
                  <td>{position.asset}</td>
                  <td>{position.optionType}</td>
                  <td>{position.strike}</td>
                  <td>{}</td>
                  <td>{}</td>
                  <td>{}</td>
                  <td>{position.expiry}</td>
                  <td> <button className="smallButton" onClick={() => handleClosePosition(position.strikeId, position.positionId, BigInt((position.quantity) * Number(UNIT)), position.optionTypeNum)}>Close Position</button></td>
                </tr>
              ))}
              {activeTable === 'historicalTrades' && historicalTrades.map((trade, index) => (
                <tr key={index}>
                  <td>{trade.buyOrSell}</td>
                  <td>{trade.asset}</td>
                  <td>{trade.optionType}</td>
                  <td>{trade.strike}</td>
                  <td>{trade.quantity}</td>
                  <td>{trade.price}</td>
                  <td>{trade.totalValue}</td>
                  <td>{trade.expiry}</td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>
    </div>
  );
}

export default Available;